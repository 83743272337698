import $ from "jquery";
import _ from "lodash";
import Backbone from "backbone";
import chroma from "chroma-js";
import DateJS from "./thirdparty/date";
import Handlebars from "./thirdparty/handlebars.runtime-v4.0.5";
import Templates from "../templates/templates.compiled";
import {AppView} from "./view/AppView";
import "./util/HandlebarsHelpers";


//***************************************
// GLOBALS

window.$ = $;
window.jQuery = $;
window._ = _;
window.Backbone = Backbone;
window.chroma = chroma;
window.Handlebars = Handlebars;

Templates.call(window, Handlebars);


//***************************************
// APP

window.budget = {};

$(function () {
	window.budget.app = new AppView({
		el: $("body")
	});
});
