import {AbstractModel} from "../../rovert/scripts/AbstractModel";
import {OwnerModels} from "./OwnerModel";
import {BankModels} from "./BankModel";
import {AccountModels} from "./AccountModel";
import {BudgetModels} from "./BudgetModel";
import {BudgetTransactionModels} from "./BudgetTransactionModel";
import {TransactionModels} from "./TransactionModel";
import {TransactionsFilterModels} from "./TransactionsFilterModel";
import {AbstractRequest} from "../net/AbstractRequest";
import {UpgradeUtil} from "../util/UpgradeUtil";

let instance;

export class AppModel extends AbstractModel {

	static get AUTO_SAVE_NAMESPACE () { return "BudgetApp"; }

	static get AUTO_SAVE_INTERVAL () { return 1000 * 10; }

	static getInstance () {
		if (!instance) {
			instance = new AppModel();
		}

		return instance;
	}

	defaults () {
		return {
			databaseLoadCompleted: false,

			fileName: null,
			saveTimestamp: null,
			databaseVersion: null,
			owners: null,
			banks: null,
			accounts: null,
			transactions: null,
			budgetTransactions: null,
			budgets: null,
			currentBudget: null,

			isDebugging: false,
		};
	}

	constructor (params) {
		super(params);

		this._autoSaveTimeout = null;

		document.addEventListener("visibilitychange", () => {
			if (document.visibilityState === "visible") {
				if (this.get("databaseLoadCompleted")) {
					this.autoSave();
				}
			} else {
				clearTimeout(this._autoSaveTimeout);
				this._autoSaveTimeout = null;
			}
		});
	}

	loadLocally () {
		let data = localStorage.getItem(AppModel.AUTO_SAVE_NAMESPACE);

		// Not try/catching because it should be impossible to happen; dev error that should be addressed.
		data = JSON.parse(data);

		this.setDatabase(data);
	}

	load () {
		this.fastSet("isDebugging", true);

		var request = new AbstractRequest("/data/database.json");
		this.listenTo(request, "success", this._handleLoadSuccess);
		request.send();
	}

	_handleLoadSuccess (request, response) {
		response = UpgradeUtil.upgradeDatabase(response);

		this.setDatabase(response);
	}

	setDatabase (response, fileName = null) {
		response = UpgradeUtil.upgradeDatabase(response);
		console.log("Database:", response);

		this.fastSet("databaseLoadCompleted", false);

		TransactionsFilterModels.getInstance().reset();
		TransactionModels.getBudgetInstance().reset();

		this.fastSet("fileName", fileName !== null ? fileName : response.fileName);
		this.fastSet("saveTimestamp", response.saveTimestamp);
		this.fastSet("databaseVersion", response.version);
		this.fastSet("owners", new OwnerModels(response.owners));
		this.fastSet("banks", new BankModels(response.banks));
		this.fastSet("accounts", new AccountModels(response.accounts));
		this.fastSet("transactions", new TransactionModels(response.transactions));
		this.fastSet("budgetTransactions", new BudgetTransactionModels(response.budgetTransactions));
		this.fastSet("budgets", new BudgetModels(response.budgets));
		this.fastSet("currentBudget", response.currentBudgetID ? this.get("budgets").get(response.currentBudgetID) : null);

		this.fastSet("databaseLoadCompleted", true);

		this.autoSave();

		this.trigger("loadComplete");
	}

	getSaveData () {
		let currentBudget = this.get("currentBudget");

		let database = {
			fileName: this.get("fileName"),
			saveTimestamp: Date.now().getTime(),
			version: this.get("databaseVersion"),
			owners: this.get("owners"),
			banks: this.get("banks"),
			accounts: this.get("accounts"),
			transactions: this.get("transactions"),
			budgetTransactions: this.get("budgetTransactions"),
			budgets: this.get("budgets"),
			currentBudgetID: currentBudget ? currentBudget.id : null
		};

		let data = JSON.stringify(database);

		return data;
	}

	save () {
		let fileName = this.get("fileName") !== null ? this.get("fileName") : "MyBudgetDatabase.json";
		let data = [ this.getSaveData() ];

		let blob = new Blob(data, { type: "application/json;charset=utf-8" });
		window.saveAs(blob, fileName);
	}

	autoSave () {
		clearTimeout(this._autoSaveTimeout);

		this.saveLocally();

		this._autoSaveTimeout = setTimeout(this.autoSave.bind(this), AppModel.AUTO_SAVE_INTERVAL);
	}

	saveLocally () {
		let data = this.getSaveData();

		localStorage.setItem(AppModel.AUTO_SAVE_NAMESPACE, data);
	}

	updateBudgetTransactions () {
		let allBudgetTransactions = TransactionModels.getBudgetInstance();
		allBudgetTransactions.reset();

		let allTransactions = this.get("transactions"),
			budget = this.get("currentBudget");

		if (!budget) { return; }

		budget.get("budgetTransactions").forEach(transaction => {
			let lastTransaction = null;

			if (allTransactions) {
				lastTransaction = allTransactions.findLastFromAccount(transaction.get("allAccountIds"));
			}

			let budgetStartDate = lastTransaction ? new Date(lastTransaction.get("date")) : null;

			if (!budgetStartDate) {
				budgetStartDate = transaction.get("startDate");
			}

			let budgetEndDate = budgetStartDate ? new Date(budgetStartDate) : null;

			if (budgetStartDate) {
				budgetStartDate.setDate(budgetStartDate.getDate() + 1);
			}

			budgetEndDate = budgetEndDate ? budgetEndDate.setFullYear(budgetEndDate.getFullYear() + 1) : null;

			let transactions = transaction.getTransactions(budgetStartDate, budgetEndDate);
			allBudgetTransactions.add(transactions);
		});

		allBudgetTransactions.updateOrder();

		for (let l = allBudgetTransactions.length, i = 0; i < l; i++) {
			let transaction = allBudgetTransactions.at(i),
				dynamicAmount = transaction.get("dynamicAmount"),
				dynamicAmountAccountIds = transaction.get("dynamicAmountAccountIds");

			if (dynamicAmount && dynamicAmountAccountIds) {
				let tFilterModel = TransactionsFilterModels.getInstance().getModel(dynamicAmountAccountIds);

				let amount = tFilterModel.getBalance(transaction.get("date"), true);

				if (dynamicAmount === "accountBalanceInverted") {
					amount *= -1;
				}

				transaction.set("amount", amount);
			}
		}
	}

}
