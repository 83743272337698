import {TransactionModel} from "../model/TransactionModel";
import {TransactionModels} from "../model/TransactionModel";

export class CSVParser {

	static get DATE_PATT () { return /^(\d{1,4})(?:\/|-|\.)(\d{1,2})(?:\/|-|\.)(\d{1,4})$$/; }

	static getArray (data, separator = ",") {
		let COLUMN_HEADERS = TransactionModel.COLUMN_HEADERS;

		let results = Papa.parse(data, {
			skipEmptyLines: true
		});

		let array = results.data,
			firstRowIsHeader = null,
			columnTypes = [],
			columnTypesSet = false;

		let rows = array,
			startRowI = 0;

		for (let numRows = rows.length, rowI = 0; rowI < numRows; rowI++) {
			let columns = rows[rowI];

			if (rowI === 0) {
				columnTypes.length = columns.length;

				for (let i = 0, l = columnTypes.length; i < l; i++) {
					columnTypes[i] = null;
				}

				// Check if first row is headers:
				for (let numColumns = columns.length, columnI = 0; columnI < numColumns; columnI++) {
					let column = columns[columnI];

					if (firstRowIsHeader !== false) {
						if (column.match(/\d/) === null) {
							if (columnI === numColumns - 1) {
								// It's likely a header.
								firstRowIsHeader = true;
								startRowI = 1;
							}
						} else {
							firstRowIsHeader = false;
						}
					}
				}
			}

			for (let numColumns = columns.length, columnI = 0; columnI < numColumns; columnI++) {
				let column = columns[columnI];

				// Guess column types:
				if (column !== "") {
					let columnType = columnTypes[columnI];

					if (columnType === null) {
						if (firstRowIsHeader && rowI === 0) {
							let columnLowerCase = column.toLowerCase();

							if (columnLowerCase.indexOf("amount") >= 0) {
								columnType = COLUMN_HEADERS.AMOUNT;
							} else if (columnLowerCase.indexOf("date") >= 0) {
								let prevDateI = columnTypes.indexOf(COLUMN_HEADERS.DATE);

								if (columnLowerCase.indexOf("post") >= 0) {
									columnType = COLUMN_HEADERS.DATE;

									if (prevDateI >= 0) {
										columnTypes[prevDateI] = COLUMN_HEADERS.TRANSACTION_DATE;
									}
								} else {
									columnType = prevDateI >= 0 ? COLUMN_HEADERS.TRANSACTION_DATE : COLUMN_HEADERS.DATE;
								}
							} else if (columnLowerCase.indexOf("description") >= 0) {
								columnType = COLUMN_HEADERS.DESCRIPTION;
							} else if (columnLowerCase.indexOf("withdrawal") >= 0 || columnLowerCase.indexOf("debit") >= 0) {
								columnType = COLUMN_HEADERS.WITHDRAWAL;
							} else if (columnLowerCase.indexOf("deposit") >= 0 || columnLowerCase.indexOf("credit") >= 0) {
								columnType = COLUMN_HEADERS.DEPOSIT;
							} else if (columnLowerCase.indexOf("balance") >= 0) {
								columnType = COLUMN_HEADERS.BALANCE;
							}
						} else if (!firstRowIsHeader && rowI >= startRowI) {
							if (column.match(/[^\d.+-]/) === null) {
								let prevAmountI = columnTypes.indexOf(COLUMN_HEADERS.AMOUNT),
									prevDepositI = columnTypes.indexOf(COLUMN_HEADERS.DEPOSIT);

								if (prevAmountI >= 0) {
									if (columnI + 1 === numColumns) {
										columnType = COLUMN_HEADERS.BALANCE;
									} else if (prevAmountI < columnI) {
										columnTypes[prevAmountI] = COLUMN_HEADERS.WITHDRAWAL;
										columnType = COLUMN_HEADERS.DEPOSIT;
									} else if (prevAmountI > columnI) {
										columnType = COLUMN_HEADERS.WITHDRAWAL;
										columnTypes[prevAmountI] = COLUMN_HEADERS.DEPOSIT;
									}
								} else if (prevDepositI >= 0) {
									columnType = COLUMN_HEADERS.BALANCE;
								} else {
									columnType = COLUMN_HEADERS.AMOUNT;
								}
							} if (column.match(CSVParser.DATE_PATT)) {
								columnType = COLUMN_HEADERS.DATE;
							} else if (columnTypes.indexOf(COLUMN_HEADERS.DESCRIPTION) === -1) {
								let wordsMatch = column.match(/[a-zA-Z\s\-]+/g);
								if (wordsMatch) {
									wordsMatch = wordsMatch.join("");
									if (wordsMatch.length >= column.length * 0.5) {
										columnType = COLUMN_HEADERS.DESCRIPTION;
									}
								}
							}
						}
					}

					columnTypes[columnI] = columnType;

					if (columnTypes.indexOf(null) === -1) {
						columnTypesSet = true;
						break;
					}
				}
			}

			if (columnTypesSet) {
				break;
			}
		}

		return {
			array: array,
			guesses: {
				firstRowIsHeader: firstRowIsHeader,
				columnTypes: columnTypes
			}
		};
	}

	static parseArray (array, columnHeaders, dateLocations, amountDisplayRatio) {
		let COLUMN_HEADERS = TransactionModel.COLUMN_HEADERS;

		let transactions = new TransactionModels();

		for (let i = 0, l = array.length; i < l; i++) {
			let row = array[i];

			let transaction = new TransactionModel();

			let column,
				columnHeader,
				matches;

			for (let j = 0, jl = row.length; j < jl; j++) {
				column = row[j];
				columnHeader = columnHeaders[j];

				if (columnHeader === null) {
					transaction.get("other")[j] = column;
					continue;
				}

				// Set data:

				switch (columnHeader) {
					case COLUMN_HEADERS.DATE:
					case COLUMN_HEADERS.TRANSACTION_DATE:
						matches = column.match(CSVParser.DATE_PATT);
						column = new Date(
							matches[dateLocations[2]],
							parseInt(matches[dateLocations[0]]) - 1,
							matches[dateLocations[1]]
						);
						break;
					case COLUMN_HEADERS.DESCRIPTION:
						column = column.trim();
						break;
					case COLUMN_HEADERS.WITHDRAWAL:
						if (column === "" || column === 0) { continue; }
						columnHeader = COLUMN_HEADERS.AMOUNT;
						column = Math.round(parseFloat(column, 10) / amountDisplayRatio);
						if (column > 0) {
							column *= -1;
						}
						break;
					case COLUMN_HEADERS.INVERTED_WITHDRAWAL:
						if (column === "" || column === 0) { continue; }
						columnHeader = COLUMN_HEADERS.AMOUNT;
						column = Math.round(parseFloat(column, 10) / amountDisplayRatio);
						if (column < 0) {
							column *= -1;
						}
						break;
					case COLUMN_HEADERS.DEPOSIT:
						if (column === "" || column === 0) { continue; }
						columnHeader = COLUMN_HEADERS.AMOUNT;
						column = Math.round(parseFloat(column, 10) / amountDisplayRatio);
						break;
					case COLUMN_HEADERS.INVERTED_DEPOSIT:
						if (column === "" || column === 0) { continue; }
						columnHeader = COLUMN_HEADERS.AMOUNT;
						column = Math.round(parseFloat(column, 10) * -1 / amountDisplayRatio);
						break;
					case COLUMN_HEADERS.AMOUNT:
					case COLUMN_HEADERS.BALANCE:
						column = Math.round(parseFloat(column || 0, 10) / amountDisplayRatio);
						break;
					case COLUMN_HEADERS.INVERTED_AMOUNT:
						columnHeader = COLUMN_HEADERS.AMOUNT;
						column = Math.round(parseFloat(column || 0, 10) * -1 / amountDisplayRatio);
						break;
					case COLUMN_HEADERS.INVERTED_BALANCE:
						columnHeader = COLUMN_HEADERS.BALANCE;
						column = Math.round(parseFloat(column || 0, 10) * -1 / amountDisplayRatio);
						break;
				}

				// row[j] = column; // For debugging.

				transaction.set(columnHeader, column);
			}

			transactions.add(transaction);
		}

		if (transactions.length >= 2 && transactions.at(0).get("date") > transactions.at(transactions.length - 1).get("date")) {
			// The transactions list is backwards to what the app is expecting, so reverse it.
			transactions.models.reverse();
		}

		return transactions;
	}

}
