import {AbstractView} from "../../rovert/scripts/AbstractView";
import {AppModel} from "../model/AppModel";
import {BudgetTransactionModel, BudgetTransactionModels} from "../model/BudgetTransactionModel";
import {BudgetTransactionListItem} from "../component/TransactionListItem";
import {TransactionEventsManager} from "../manager/TransactionEventsManager";

export class BudgetView extends AbstractView {

	get events () {
		return {
			"change #budgetView_showOldCheckbox": "_handleShowOldCheckboxChanged",
			"click .budgetView_newBudgetTransactionBtn": "_handleNewBudgetTransactionBtnClicked",
		};
	}

	constructor (params) {
		super(params);

		this.transactionEventsManager = new TransactionEventsManager();

		this.transactionsListEl = null;
		this.transactionsListHeaderEl = null;

		this.setTemplate("view.BudgetView");
	}

	_configUIBeforeReplace () {
		this.transactionsListEl = this.el.querySelector(".budgetView_transactions");
		this.transactionsListHeaderEl = this.transactionsListEl.querySelector(".transactionListItem-header");

		this.refreshList();
	}

	refreshList () {
		this.transactionsListEl.innerHTML = "";
		this.transactionsListEl.appendChild(this.transactionsListHeaderEl);

		const showOld = this.el.querySelector("#budgetView_showOldCheckbox").checked;

		let transactions = this.model.get("budgetTransactions");
		transactions.forEach(transaction => {
			transaction.updateAccountLabel();

			if (!showOld && transaction.get("isOld")) { return; }

			let item = new BudgetTransactionListItem({
				model: transaction,
				fieldsDirectlyEditable: true,
			});

			this.listenTo(item, BudgetTransactionListItem.REMOVE_BTN_CLICKED, () => {
				this.transactionsListEl.removeChild(item.el);
				this.model.removeTransaction(transaction);

				AppModel.getInstance().updateBudgetTransactions();
			});

			this.transactionEventsManager.addTransaction(item);

			this.transactionsListEl.appendChild(item.el);
		});
	}

	_handleShowOldCheckboxChanged (event) {
		this.refreshList();
	}

	_handleNewBudgetTransactionBtnClicked (event) {
		let transaction = new BudgetTransactionModel({
			startDate: new Date(),
			id: BudgetTransactionModels.getInstance().getNewId()
		});

		this.model.addTransaction(transaction);

		this.refreshList();
	}

	cleanUp () {
		super.cleanUp();

		this.transactionEventsManager.cleanUp();
		this.transactionEventsManager = null;
	}

}
