import Handlebars from "../thirdparty/handlebars.runtime-v4.0.5";

/**
 * Covert double spaces to "&nbsp;&nbsp;" so they are seen in HTML:
 * @method convertSpaces
 * @param  {String} string The string to parse.
 * @param  {Object} options
 * @return {String}
 */
Handlebars.registerHelper("convertSpaces", (string, options) => {
	return typeof string === "string" ? string.replace(/ {2}/g, "&nbsp;&nbsp;") : string;
});
