import {NumberUtils} from "../../rovert/scripts/NumberUtils";

import {DialogActionModel} from "../model/DialogActionModel";
import {DialogModel} from "../model/DialogModel";
import {AccountModels} from "../model/AccountModel";
import {AbstractDialog} from "./AbstractDialog";

export class BudgetAmountDialog extends AbstractDialog {

	get events () {
		return {
		};
	}

	constructor (params) {
		params = params || {};

		params.model = new DialogModel({
			title: "Set Amount",
			actions: [
				new DialogActionModel({ id: "cancel", label: "Cancel" }),
				new DialogActionModel({ id: "set", label: "Set" }),
			],
			budgetAmountDialog_transaction: null, // [BudgetTransactionModel]
			budgetAmountDialog_choice: null, // [String] amount, dynamicAmount
			budgetAmountDialog_amount: null, // [Number]
			budgetAmountDialog_dynamicAmount: null, // [String]
			budgetAmountDialog_dynamicAmountAccountIds: null, // [String]
		});

		super(params);

		this.$dynamicAmountType = null;

		this.$accountsTree = null;
		this.accountsTree = null;

		this.setTemplate("dialog.BudgetAmountDialog");

		this.listenTo(this.model, "change:budgetAmountDialog_choice", this.renderChoice);
		this.listenTo(this.model, "change:budgetAmountDialog_amount", this.renderAmount);
		this.listenTo(this.model, "change:budgetAmountDialog_dynamicAmount", this.renderDynamicAmountType);
	}

	/**
	 * Override.
	 */
	_configUIBeforeReplace () {
		super._configUIBeforeReplace();

		this.$("#budgetAmountDialog_amountChoice").change(this._handleAmountChoiceChanged.bind(this));
		this.$("#budgetAmountDialog_dynamicAmountChoice").change(this._handleDynamicAmountChoiceChanged.bind(this));

		this.$(".budgetAmountDialog_amountInput").change(this._handleAmountChanged.bind(this));

		this.$dynamicAmountType = this.$(".budgetAmountDialog_dynamicAmountTypeInput");
		this.$dynamicAmountType.change(this._handleDynamicAmountTypeChanged.bind(this));

		this.$accountsTree = this.$(".budgetAmountDialog_accountsTree");
		this.$accountsTree.on("dblclick", ".jstree-anchor", this._handleAccountsTreeItemDoubleClicked.bind(this));

		this.renderAccountsTree();
	}

	_handleAmountChoiceChanged (event) {
		this.model.set("budgetAmountDialog_choice", "amount");
	}

	_handleDynamicAmountChoiceChanged (event) {
		this.model.set("budgetAmountDialog_choice", "dynamicAmount");
		this._handleDynamicAmountTypeChanged();
	}

	_handleAmountChanged (event) {
		let value = Number(event.target.value);
		value = Math.round(value / this.model.get("budgetAmountDialog_transaction").get("amountDisplayRatio"));

		this.model.set("budgetAmountDialog_amount", value);
	}

	_handleDynamicAmountTypeChanged (event) {
		let dynamicAmount = this.$dynamicAmountType.val();
		this.model.set("budgetAmountDialog_dynamicAmount", dynamicAmount);
	}

	renderChoice () {
		let choice = this.model.get("budgetAmountDialog_choice");

		this.$(".budgetAmountDialog_amount").toggleClass("hidden", choice !== "amount");
		this.$(".budgetAmountDialog_dynamicAmount").toggleClass("hidden", choice !== "dynamicAmount");
	}

	renderAmount () {
		let amount = this.model.get("budgetAmountDialog_amount") * this.model.get("budgetAmountDialog_transaction").get("amountDisplayRatio");
		amount = NumberUtils.toFixed(amount, 2);
		this.$(".budgetAmountDialog_amountInput").val(amount);
	}

	renderDynamicAmountType () {
		this.$dynamicAmountType.val(this.model.get("budgetAmountDialog_dynamicAmount"));
	}

	renderAccountsTree () {
		let accounts = AccountModels.getInstance();
		let accountsTree = accounts.getJSTreeData();

		if (this.accountsTree) {
			this.accountsTree.destroy();
		}

		this.$accountsTree.jstree({
			core: {
				data: accountsTree
			}
		});

		this.accountsTree = this.$accountsTree.jstree();
	}

	_handleAccountsTreeItemDoubleClicked (event) {
		let accountId = event.target.id.replace("_anchor", "");
		if (accountId) {
			let accounts = AccountModels.getInstance();
			let account = accounts.get(accountId);

			this.model.set("budgetAmountDialog_dynamicAmountAccountIds", account.id);
		}
	}

	setTransaction (budgetTransaction) {
		this.model.set("budgetAmountDialog_transaction", budgetTransaction);

		let choice = budgetTransaction.get("dynamicAmount") !== null ? "dynamicAmount" : "amount";
		this.model.set("budgetAmountDialog_choice", choice);

		this.model.set("budgetAmountDialog_amount", budgetTransaction.get("amount"));
	}

	/**
	 * Override.
	 */
	_handleActionBtnClick (btn) {
		if (btn.model.id === "set") {
			let budgetTransaction = this.model.get("budgetAmountDialog_transaction");
			let choice = this.model.get("budgetAmountDialog_choice");

			budgetTransaction.set("amount", choice === "amount" ? this.model.get("budgetAmountDialog_amount") : null);
			budgetTransaction.set("dynamicAmount", choice === "dynamicAmount" ? this.model.get("budgetAmountDialog_dynamicAmount") : null);
			budgetTransaction.set("dynamicAmountAccountIds", choice === "dynamicAmount" ? this.model.get("budgetAmountDialog_dynamicAmountAccountIds") : null);
		}

		this.close();
	}

}
