import {AbstractView} from "../../rovert/scripts/AbstractView";
import {DialogActionBtn} from "../component/DialogActionBtn";
import {DialogManager} from "../manager/DialogManager";

export class AbstractDialog extends AbstractView {

	/**
	 * Override.
	 */
	constructor (params) {
		super(params);

		if (this.model) {
			this.listenTo(this.model, "change:title", this._updateTitle);
			this.listenTo(this.model, "change:actions", this._updateActions);
		}

		this.$title = null;
		this.$body = null;
		this.$actions = null;

		this._subTemplate = null;

		this._lastScrollY = 0;
	}

	/**
	 * Override.
	 */
	setTemplate (url) {
		this._subTemplate = templates[url];

		super.setTemplate("dialog.BaseDialog");
	}

	/**
	 * Override.
	 */
	_configUIBeforeReplace () {
		this.$title = this.$(".dialog_titleText");
		this.$body = this.$(".dialog_body");

		this.$actions = this.$(".dialog_actions");
		this._updateActions();

		var $subEl = $(this._subTemplate(this._getTemplateData()));

		this.el.classList.add($subEl.attr("class"));
		this.$body.append($subEl.children());
	}

	open () {
		DialogManager.open(this);
	}

	close () {
		DialogManager.close(this);
	}

	_updateTitle () {
		let title = this.model.get("title");
		this.$title.html(title);
	}

	_toggleBodyScroll (enabled) {
		if (!enabled) {
			this._lastScrollY = this.$body.scrollTop();
		}

		this.$body[0].style.overflow = enabled ? "" : "hidden";

		if (enabled) {
			this.$body.scrollTop(this._lastScrollY);
		} else {
			this.$body.scrollTop(0);
		}
	}

	getActions () {
		return this.model.getActions();
	}

	setActions (actions) {
		this.model.setActions(actions);
	}

	_updateActions () {
		this.$actions.empty();

		let actions = this.model.get("actions");
		actions.forEach(action => {
			let actionBtn = new DialogActionBtn({ model: action });
			this.listenTo(actionBtn, "click", this._handleActionBtnClick);

			this.$actions.append(actionBtn.el);
		});
	}

	_handleActionBtnClick () {}

	/**
	 * Override.
	 */
	cleanUp () {
		super.cleanUp();
	}

}
