import {AbstractView} from "../../rovert/scripts/AbstractView";
import {Routes} from "../data/Routes";
import {AppModel} from "../model/AppModel";
import {AccountModels} from "../model/AccountModel";
import {BudgetModels} from "../model/BudgetModel";
import {ServerDelegate} from "../net/ServerDelegate";
import {DialogManager} from "../manager/DialogManager";
import {ViewManager} from "../manager/ViewManager";
import {AppRouter} from "../router/AppRouter";
import {MainHeader} from "../menu/MainHeader";
import {HomeView} from "../view/HomeView";
import {LoadView} from "../view/LoadView";
import {AccountsView} from "../view/AccountsView";
import {AccountView} from "../view/AccountView";
import {AccountTransactionsView} from "../view/AccountTransactionsView";
import {BudgetsView} from "../view/BudgetsView";
import {BudgetView} from "../view/BudgetView";
import {ReportsView} from "../view/ReportsView";

export class AppView extends AbstractView {

	constructor (params) {
		super(params);

		this.router = null;
		this.mainHeader = null;

		this._queuedLoadRouteData = null;

		this._initModels();
		this._initRouter();
		this._initDialogs();
		this._initViews();

		this.router.start();

		// window.__budget_debugging = true; // Uncomment to enable debugging.

		//*
		this.model.loadLocally();
		/*/
		// Load local database.json file:
		this.loadLocalDebugDatabase();
		//*/
	}

	_initModels () {
		this.model = AppModel.getInstance();
		this.listenTo(this.model, "loadComplete", this._handleModelLoadComplete);
	}

	_handleModelLoadComplete () {
		if (this._queuedLoadRouteData) {
			const queuedLoadRouteData = this._queuedLoadRouteData;
			this._queuedLoadRouteData = null;

			AppRouter.getInstance().navigate(
				queuedLoadRouteData.pathname + queuedLoadRouteData.hash.slice(1),
				{ trigger: true }
			);
		} else {
			AppRouter.getInstance().navigate(Routes.ACCOUNTS, { trigger: true });
		}
	}

	_initRouter () {
		this.router = AppRouter.getInstance();
	}

	_initDialogs () {
		DialogManager.getInstance().initialize({
			el: $("#dialogs")
		});
	}

	_initViews () {
		this.mainHeader = new MainHeader({
			el: $("#mainHeader")
		});

		ViewManager.getInstance().initialize({
			el: $("#views"),
			model: this.model,
			createViewFunc: this._createView.bind(this)
		});
	}

	_createView (route, subRoutes) {
		var view = null;

		if (route !== Routes.LOAD && !this.model.get("databaseLoadCompleted")) {
			this._queuedLoadRouteData = {
				pathname: window.location.pathname,
				search: window.location.search,
				hash: window.location.hash
			};

			AppRouter.getInstance().navigate(Routes.LOAD, { trigger: true });

			return;
		}

		switch (route) {

			case Routes.HOME:
				view = new HomeView({
					model: this.model
				});
				break;

			case Routes.LOAD:
				view = new LoadView({
					model: this.model
				});
				break;

			case Routes.ACCOUNTS:
				view = new AccountsView({
				});
				break;

			case Routes.ACCOUNT:
				var account = AccountModels.getInstance().get(subRoutes[0]);
				if (account) {
					view = new AccountView({
						model: account
					});
				}
				break;

			case Routes.ACCOUNT_TRANSACTIONS:
				var account = AccountModels.getInstance().get(subRoutes[0]);
				if (account) {
					view = new AccountTransactionsView({
						model: account
					});
				}
				break;

			case Routes.BUDGETS:
				view = new BudgetsView({
				});
				break;

			case Routes.BUDGET:
				var budget = BudgetModels.getInstance().get(subRoutes[0]);
				if (budget) {
					view = new BudgetView({
						model: budget
					});
				}
				break;

			case Routes.REPORTS:
				view = new ReportsView({
				});
				break;

		}

		return view;
	}

	loadLocalDebugDatabase () {
		this.model.load();
	}

}
