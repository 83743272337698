import {AbstractModel, AbstractCollection} from "../../rovert/scripts/AbstractModel";
import {AppModel} from "./AppModel";
import {BudgetTransactionModels} from "./BudgetTransactionModel";

export class BudgetModel extends AbstractModel {

	defaults () {
		return {
			// id: -1,
			name: null, // [String]
			budgetTransactions: null // [Array[String]->BudgetTransactionModels]
		};
	}

	constructor (params) {
		super(params);

		let budgetTransactions = this.get("budgetTransactions");

		if (budgetTransactions === null) {
			this.set("budgetTransactions", new BudgetTransactionModels());
		} else if (budgetTransactions instanceof Array) {
			let globalBudgetTransactions = BudgetTransactionModels.getInstance();

			budgetTransactions = budgetTransactions.map(id => {
				return globalBudgetTransactions.get(id);
			});

			this.set("budgetTransactions", new BudgetTransactionModels(budgetTransactions));
		}
	}

	addTransaction (model) {
		this.get("budgetTransactions").add(model);

		BudgetTransactionModels.getInstance().add(model);
	}

	removeTransaction (model) {
		this.get("budgetTransactions").remove(model);

		BudgetTransactionModels.getInstance().remove(model);
	}

	toJSON () {
		let budgetTransactions = this.attributes.budgetTransactions;
		if (budgetTransactions.length > 0) {
			budgetTransactions = budgetTransactions.map(transaction => transaction.id);
		} else {
			budgetTransactions = null;
		}

		let json = {
			id: this.attributes.id,
			name: this.attributes.name,
			budgetTransactions: budgetTransactions,
		};

		for (var key in json) {
			if (json[key] === null) {
				delete json[key];
			}
		}

		return json;
	}
}

export class BudgetModels extends AbstractCollection {

	static getInstance () {
		return AppModel.getInstance().get("budgets");
	}

	get model () { return BudgetModel; }

}
