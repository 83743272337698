import _ from "underscore";
import Backbone from "backbone";
import {AppRouter} from "../router/AppRouter";

let instance = null;

export class ViewManager {

	static getInstance () {
		if (!instance) {
			instance = new ViewManager();
		}

		return instance;
	}

	constructor (params) {
		_.extend(this, Backbone.Events);

		this.$el = null;
		this.el = null;
		this.model = null;

		this._createView = null;
	}

	initialize (params) {
		this.$el = $(params.el);
		this.el = this.$el[0];

		this.model = params.model;
		this._createView = params.createViewFunc;

		this.listenTo(AppRouter.getInstance(), "route", this._handleRoute);

		$(document.body).on("click", "a", this._handleATagClick.bind(this));
	}

	_handleRoute (route, subRoutes) {
		console.log("New Route:", route, subRoutes);

		var newView = this._createView(route, subRoutes);

		if (newView) {
			this._showView(newView);
		}
	}

	_showView (newView) {
		if (this._currentView) {
			this._currentView.cleanUp();
			this._currentView.remove();
			this._currentView = null;
		}

		if (newView) {
			this._currentView = newView;
			this.el.appendChild(this._currentView.el);
		}
	}

	_handleATagClick (event) {
		let target = event.currentTarget || event.target,
			href = target.getAttribute("href");

		if (href === "") {// || href === "#") {
			// Likely just a button click in a view.
			event.preventDefault();
		}
	}

}
