import {AbstractModel, AbstractCollection} from "../../rovert/scripts/AbstractModel";

export class DialogActionModel extends AbstractModel {

	defaults () {
		return {
			label: "",
			disabled: false,
			data: null
		};
	}

}

export class DialogActionModels extends AbstractCollection {

	get model () { return DialogActionModel; }

}
