import {AbstractView} from "../../rovert/scripts/AbstractView";
import {SimpleTemplateView} from "../../rovert/scripts/SimpleTemplateView";
import {BudgetModel, BudgetModels} from "../model/BudgetModel";

export class BudgetsView extends AbstractView {

	get events () {
		return {
			"click .budgetsView_newBudgetBtn": "_handleNewBudgetBtnClicked"
		};
	}

	constructor (params) {
		super(params);

		this.$budgetsList = null;

		this.setTemplate("view.BudgetsView");

		let budgets = BudgetModels.getInstance();
		this.listenTo(budgets, "add", this._handleBudgetsAdd);
	}

	_configUIBeforeReplace () {
		this.$budgetsList = this.$(".budgetsView_budgetsList");

		this.refreshList();
	}

	refreshList () {
		this.$budgetsList.empty();

		let budgets = BudgetModels.getInstance();
		console.log(budgets);

		budgets.forEach((budget) => {
			let $budget = $("<div><a href='#budget/" + budget.cid + "'>" + budget.get("name") + " - " + budget.id + "</a></div>");

			this.$budgetsList.append($budget);
		});
	}

	_handleBudgetsAdd (account, accounts, options) {
		this.refreshList();
	}

	_handleNewBudgetBtnClicked (event) {
		let budget = new BudgetModel();
		budget.set("name", budget.cid);

		BudgetModels.getInstance().add(budget);
	}

}
