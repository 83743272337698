import {NumberUtils} from "../../rovert/scripts/NumberUtils";
import {AbstractView} from "../../rovert/scripts/AbstractView";

export class TransactionListItem extends AbstractView {

	static get FIELD_CLICKED () { return "TransactionListItem.FIELD_CLICKED"; }

	get events () {
		return {
			"click .transactionListItem_field": "_handleFieldClick",
		};
	}

	constructor (params) {
		super(params);

		this.fieldsDirectlyEditable = this.getParam("fieldsDirectlyEditable", false);

		this.setTemplate(this.getParam("template", "component.TransactionListItem"));
	}

	/**
	 * Override.
	 */
	_configUIBeforeReplace () {
		this.el.setAttribute("data-fieldsDirectlyEditable", this.fieldsDirectlyEditable);

		let fieldEls = this.el.querySelectorAll(".transactionListItem_field");
		fieldEls.forEach(fieldEl => {
			let readProp = this._getReadProp(fieldEl);

			if (readProp) {
				this._setUpValueListener(readProp, fieldEl);
			}
		});
	}

	_handleFieldClick (event) {
		let fieldEl = event.target,
			fieldType = fieldEl.getAttribute("data-type"),
			fieldProp = fieldEl.getAttribute("data-prop");

		this.trigger(TransactionListItem.FIELD_CLICKED, this, fieldEl, fieldType, fieldProp);
	}

	editTextField (fieldEl, fieldProp, charRestrictPattern = null, isNumber = false, isAmount = false) {
		let value = this.model.get(fieldProp);

		if (isAmount && value !== null) {
			value = NumberUtils.toFixed(value * this.model.get("amountDisplayRatio"), 2);
		}

		fieldEl.innerHTML = "";

		let inputEl = document.createElement("input");
		inputEl.type = isNumber ? "number" : "text";
		inputEl.className = "transactionListItem_fieldInput";
		inputEl.value = value;
		inputEl.__budget_lastValue = inputEl.value;
		inputEl.addEventListener("blur", this._handleTextInputBlur.bind(this, fieldEl, fieldProp, isNumber, isAmount));

		if (charRestrictPattern) {
			inputEl.addEventListener("input", this._handleTextInputInput.bind(this, charRestrictPattern));
		}

		fieldEl.appendChild(inputEl);

		inputEl.focus();

		if (!isNumber && !!value) {
			inputEl.setSelectionRange(0, value.length);
		}
	}

	_handleTextInputInput (charRestrictPattern, event) {
		let inputEl = event.target;
		let value = inputEl.value;
		let isValueValid = value === "" || value.match(charRestrictPattern) !== null;

		if (!isValueValid) {
			value = inputEl.__budget_lastValue;
			inputEl.value = value;
		}

		inputEl.__budget_lastValue = value;
	}

	_handleTextInputBlur (fieldEl, fieldProp, isNumber, isAmount, event) {
		let value = event.target.value;

		if (value === "") {
			value = null;
		} else if (isNumber) {
			value = parseFloat(value);
		} else if (isAmount) {
			value = Math.round(parseFloat(value) / this.model.get("amountDisplayRatio"));
		}

		this.model.set(fieldProp, value);
		this._updateFieldValue(fieldEl);
	}

	editDateField (fieldEl, fieldProp) {
		let value = this.model.get(fieldProp);

		if (value) {
			value = `${value.getFullYear()}-${(value.getMonth() + 1).toString().padStart(2, "0")}-${value.getDate().toString().padStart(2, "0")}`;
		}

		fieldEl.innerHTML = "";

		let inputEl = document.createElement("input");
		inputEl.type = "date";
		inputEl.className = "transactionListItem_fieldInput";
		inputEl.value = value;
		inputEl.addEventListener("change", this._handleDateInputChange.bind(this));
		inputEl.addEventListener("blur", this._handleDateInputBlur.bind(this, fieldEl, fieldProp));

		fieldEl.appendChild(inputEl);

		inputEl.focus();
	}

	_handleDateInputChange (event) {
		event.target.blur();
	}

	_handleDateInputBlur (fieldEl, fieldProp, event) {
		let value = event.target.value;

		if (value === "") {
			value = null;
		} else {
			value = new Date(value);

			value.setHours(value.getHours() + (value.getTimezoneOffset() / 60));
		}

		this.model.set(fieldProp, value);
		this._updateFieldValue(fieldEl);
	}

	editDropdownField (fieldEl, fieldProp, options, processDecisionCallback = null) {
		let value = this.model.get(fieldProp);

		fieldEl.innerHTML = "";
		fieldEl.__budget_valueSet = false;

		let inputEl = document.createElement("select");
		inputEl.className = "transactionListItem_fieldInput";

		options.forEach(option => {
			let optionEl = document.createElement("option");
			optionEl.value = option.value;
			optionEl.innerText = option.label;
			optionEl.defaultSelected = option.value === value;
			inputEl.appendChild(optionEl);
		});

		inputEl.addEventListener("input", this._handleDropdownChange.bind(this, fieldEl, fieldProp, processDecisionCallback));
		inputEl.addEventListener("blur", this._handleDropdownBlur.bind(this, fieldEl));

		fieldEl.appendChild(inputEl);

		inputEl.focus();
	}

	_handleDropdownChange (fieldEl, fieldProp, processDecisionCallback, event) {
		let value = event.target.value;

		value = processDecisionCallback ? processDecisionCallback(value) : value;

		this.model.set(fieldProp, value);
		this._updateFieldValue(fieldEl);
	}

	_handleDropdownBlur (fieldEl, event) {
		// Need to check __budget_valueSet because of a Chrome bug.
		// Chrome will throw a DOMException if child DOM is asyncronously removed during
		// a element.innerHTML = foo; call.
		if (fieldEl.__budget_valueSet) { return; }

		this._updateFieldValue(fieldEl);
	}

	_getReadProp (fieldEl) {
		let writeProp = fieldEl.getAttribute("data-prop"),
			readProp = fieldEl.getAttribute("data-readProp") || writeProp;

		return readProp;
	}

	_setUpValueListener (prop, el) {
		this.listenTo(this.model, "change:" + prop, this._handleValueChange.bind(this, el));
	}

	_handleValueChange (el, model, value, options) {
		el.__budget_valueSet = true;

		el.innerHTML = value;
	}

	_updateFieldValue (fieldEl) {
		let readProp = this._getReadProp(fieldEl),
			readValue = this.model.get(readProp);

		if (fieldEl.innerHTML !== readValue) {
			fieldEl.innerHTML = readValue;
		}
	}

}

export class BudgetTransactionListItem extends TransactionListItem {

	static get REMOVE_BTN_CLICKED () { return "BudgetTransactionListItem.REMOVE_BTN_CLICKED"; }

	get events () {
		return _.extend({}, super.events, {
			"click .transactionListItem_remove button": "_handleRemoveButtonClick",
		});
	}

	constructor (params) {
		params.template = "component.BudgetTransactionListItem";

		super(params);
	}

	_handleRemoveButtonClick (event) {
		this.trigger(BudgetTransactionListItem.REMOVE_BTN_CLICKED, this);
	}

}
