import {AbstractModel, AbstractCollection} from "../../rovert/scripts/AbstractModel";
import {AppModel} from "./AppModel";
import {AccountModels} from "./AccountModel";

let budgetInstance;

export class TransactionModel extends AbstractModel {

	static get COLUMN_HEADERS () {
		return this._COLUMN_HEADERS || (this._COLUMN_HEADERS = {
			DATE: "date",
			TRANSACTION_DATE: "transactionDate",
			DESCRIPTION: "description",
			WITHDRAWAL: "withdrawal",
			INVERTED_WITHDRAWAL: "invertedWithdrawal",
			DEPOSIT: "deposit",
			INVERTED_DEPOSIT: "invertedDeposit",
			AMOUNT: "amount",
			INVERTED_AMOUNT: "invertedAmount",
			BALANCE: "balance",
			INVERTED_BALANCE: "invertedBalance"
		});
	}

	static get COLUMN_HEADER_NAMES () {
		return this._COLUMN_HEADER_NAMES || (this._COLUMN_HEADER_NAMES = {
			DATE: "Post Date",
			TRANSACTION_DATE: "Transaction Date",
			DESCRIPTION: "Description",
			WITHDRAWAL: "Withdrawal",
			INVERTED_WITHDRAWAL: "Withdrawal (inverted)",
			DEPOSIT: "Deposit",
			INVERTED_DEPOSIT: "Deposit (inverted)",
			AMOUNT: "Amount",
			INVERTED_AMOUNT: "Amount (inverted)",
			BALANCE: "Balance",
			INVERTED_BALANCE: "Balance (inverted)"
		});
	}

	static IS_SAME_ACCOUNTS (t1, t2) {
		let t1c = t1.get("dstAccountIds"),
			t2c = t2.get("dstAccountIds");

		for (let i = 0, l = t1c.length; i < l; i++) {
			if (!t2c[i] || t1c[i].id !== t2c[i].id) {
				return false;
			}
		}

		return true;
	}

	defaults () {
		return {
			// id: -1,
			srcAccountId: null, // [String]
			srcAccountIdText: null, // [String]
			dstAccountIds: null, // [Array[Object{id, amount}]]
			dstAccountIdsText: null, // [String]
			date: null, // [Number->Date] The date the transaction was posted.
			dateText: null, // [String]
			transactionDate: null, // [Number->Date]
			description: null, // [String]
			amount: 0, // [Int]
			amountText: null, // [String]
			other: [], // [Array[String]]

			dynamicAmount: null, // [String]
			dynamicAmountAccountIds: null, // [String]

			amountDisplayRatio: 0.01, // [Number] The number to multiply amounts by for their display values.
			allAccountIds: null, // [Array[String]]

			accountConfidence: null, // [Number]

			// Properties for transaction lists:
			viewAccountIds: null, // [Array[String]]
			viewLabel: null, // [String]
			deposit: 0, // [Int]
			depositText: null, // [String]
			withdrawal: 0, // [Int]
			withdrawalText: null, // [String]
			balance: 0, // [Int]
			balanceText: null, // [String]
		};
	}

	constructor (params) {
		super(params);

		if (typeof this.get("date") === "string") {
			this.set("date", new Date(this.get("date")));
		}
		if (typeof this.get("transactionDate") === "string") {
			this.set("transactionDate", new Date(this.get("transactionDate")));
		}

		this.on("change:date", this.updateDateText, this);
		this.updateDateText();

		this.on("change:amount", this.updateAmountsText, this);
		this.on("change:balance", this.updateAmountsText, this);
		this.on("change:dynamicAmount", this.updateAmountsText, this);
		this.on("change:dynamicAmountAccountIds", this.updateAmountsText, this);
		this.updateAmountsText();

		this.setDstAccountIds(this.get("dstAccountIds"));
		this.on("change:srcAccountId", this.updateAccountLabel.bind(this, null));
		this.on("change:dstAccountIds", this.updateAccountLabel.bind(this, null));

		this.on("change:srcAccountId", this.updateAllAccountIds, this);
		this.on("change:dstAccountIds", this.updateAllAccountIds, this);
		this.updateAllAccountIds();

		this.on("change:amount", this.updateDstAccountIds, this);
		this.on("change:amount", this.updateDepositWithdrawal, this);
		this.updateDepositWithdrawal();

		this.on("change:deposit", this.updateAmount, this);
		this.on("change:withdrawal", this.updateAmount, this);
	}

	setDstAccountIds (value) {
		if (Array.isArray(value)) {
			// Assume it's formatted correctly as Array<{id:string, amount:number}>
		} else if (typeof value === "string") {
			value = [{
				id: value,
				amount: this.get("amount")
			}];
		} else if (value === null) {
			value = [];
		} else {
			throw new Error("TransactionModel.setDstAccountIds() formatted wrong: " + value);
		}

		this.set("dstAccountIds", value);
	}

	removeSrcAccount (id) {
		if (this.get("srcAccountId") === id) {
			this.set("srcAccountId", null);
		}
	}

	getDstAccountIds () {
		return this.get("dstAccountIds").map(item => item.id);
	}

	removeDstAccount (id) {
		let dstAccountIds = this.get("dstAccountIds"),
			dstAccount;

		for (let l = dstAccountIds.length, i = 0; i < l; i++) {
			dstAccount = dstAccountIds[i];
			if (dstAccount.id === id) {
				dstAccountIds.splice(i, 1);
				break;
			}
		}

	}

	updateAllAccountIds () {
		let allAccountIds = [this.get("srcAccountId")].concat(this.get("dstAccountIds").map(i => i.id));
		this.set("allAccountIds", allAccountIds);
	}

	updateDateText () {
		let date = this.get("date"),
			dateText = date ? date.toString("MM/dd/yyyy") : "";

		this.set("dateText", dateText);
	}

	updateAmountsText () {
		let amount = this.get("amount"),
			dynamicAmount = this.get("dynamicAmount"),
			dynamicAmountAccountIds = this.get("dynamicAmountAccountIds"),
			deposit = this.get("deposit"),
			withdrawal = this.get("withdrawal"),
			amountDisplayRatio = this.get("amountDisplayRatio");

		let amountText = "";

		if (dynamicAmount) {
			amountText = dynamicAmount + " from " + (dynamicAmountAccountIds || "[not specified]");
		} else if (amount !== null) {
			amountText = (amount * amountDisplayRatio).toLocaleString(undefined, { minimumFractionDigits: 2 });
		}

		this.set({
			amountText: amountText,
			depositText: deposit !== 0 ? (deposit * amountDisplayRatio).toLocaleString(undefined, { minimumFractionDigits: 2 }) : "",
			withdrawalText: withdrawal !== 0 ? (withdrawal * amountDisplayRatio).toLocaleString(undefined, { minimumFractionDigits: 2 }) : "",
			balanceText: (this.get("balance") * amountDisplayRatio).toLocaleString(undefined, { minimumFractionDigits: 2 })
		});
	}

	updateDstAccountIds () {
		let dstAccountIds = this.get("dstAccountIds");
		if (dstAccountIds.length === 1) {
			dstAccountIds[0].amount = this.get("amount");
		}
	}

	updateAccountLabel (viewAccountIds = null) {
		if (viewAccountIds === null) {
			viewAccountIds = this.get("viewAccountIds");
		} else {
			this.set("viewAccountIds", viewAccountIds);
		}

		let label = "",
			srcAccountIdText = "",
			dstAccountIdsText = "";

		let accountModels = AccountModels.getInstance();

		let srcAccountId = this.get("srcAccountId"),
			dstAccountIds = this.get("dstAccountIds"),
			srcAccount = accountModels.get(srcAccountId),
			dstAccounts = dstAccountIds.map(account => accountModels.get(account.id));

		if (srcAccount) {
			srcAccountIdText = srcAccount.get("name");
		}

		dstAccountIdsText = dstAccounts.map(account => account.get("name")).join(", ");

		if (viewAccountIds !== null) {
			let useDstAccountIds = null;

			let _dstAccountIds = dstAccountIds.map(dstAccountData => dstAccountData.id);

			for (let i = 0, l = viewAccountIds.length; i < l; i++) {
				let viewAccountId = viewAccountIds[i];
				accountModels.forEachAncestor(viewAccountId, true, _account => {
					let _id = _account.id;

					if (_id === srcAccountId) {
						useDstAccountIds = true;
						return false;
					} else {
						for (let j = 0, jl = _dstAccountIds.length; j < jl; j++) {
							let _srcAccountId = _dstAccountIds[j];
							if (_id === _srcAccountId) {
								useDstAccountIds = false;
								return false;
							}
						}
					}
				});

				if (useDstAccountIds !== null) { break; }
			}

			if (useDstAccountIds === null) {
				// Default to true:
				useDstAccountIds = true;
			}

			if (viewAccountIds.length === 1 && viewAccountIds[0] === srcAccountId) {
				useDstAccountIds = true;
			} else {
				let diffDstAccountIds = _.difference(viewAccountIds, _dstAccountIds);

				if (diffDstAccountIds.length === viewAccountIds.length) {
					useDstAccountIds = true;
				}
			}

			if (useDstAccountIds === true || useDstAccountIds === null) {
				if (dstAccountIds.length === 0) {
					label = "-- None --";
				} else if (dstAccountIds.length === 1) {
					let firstDstAccountId = dstAccountIds[0].id;
					// label = accountModels.getAncestralName(firstDstAccountId);
					label = firstDstAccountId + " - " + accountModels.getAncestralName(firstDstAccountId);
				} else {
					label = "-- Split --";
				}
			} else if (srcAccountId) {
				label = srcAccountId + " - " + accountModels.getAncestralName(srcAccountId);
			} else {
				label = "-- None --";
			}
		}

		this.set({
			viewLabel: label,
			srcAccountIdText: srcAccountIdText,
			dstAccountIdsText: dstAccountIdsText,
		});
	}

	getAccountAmount (dstAccountId) {
		let dstAccountIds = this.get("dstAccountIds");
		for (let i = 0, l = dstAccountIds.length; i < l; i++) {
			let dstAccountData = dstAccountIds[i];
			if (dstAccountId === dstAccountData.id) {
				return dstAccountData.amount;
			}
		}

		return 0;
	}

	getAccountConfidenceText () {
		let accountConfidence = this.get("accountConfidence"),
			accountConfidenceText = "";

		if (accountConfidence !== null) {
			accountConfidenceText = ((accountConfidence * 100) | 0) + "%";
		}

		return accountConfidenceText;
	}

	hasAccount (id, includeParents = false, accountModels = null) {
		let accountIds = this.get("allAccountIds");

		for (let i = 0, l = accountIds.length; i < l; i++) {
			let accountId = accountIds[i];
			if (accountId === id) {
				return true;
			} else if (includeParents) {
				while (accountId = accountModels.get(accountId).get("parentAccountId")) {
					if (accountId === id) {
						return true;
					}
				}
			}
		}

		return false;
	}

	updateDepositWithdrawal () {
		var amount = this.get("amount");
		this.set({
			deposit: amount > 0 ? amount : 0,
			withdrawal: amount < 0 ? Math.abs(amount) : 0
		});
		this.updateAmountsText();
	}

	updateAmount () {
		let amount = this.get("amount"),
			deposit = this.get("deposit"),
			withdrawal = this.get("withdrawal");

		if (deposit > 0 && amount !== deposit) {
			this.set("amount", deposit);
		} else if (withdrawal > 0 && amount !== withdrawal * -1) {
			this.set("amount", withdrawal * -1);
		} else if (deposit === 0 && withdrawal === 0) {
			this.set("amount", 0);
		}
	}

	toJSON () {
		let _attributes = this.attributes;

		let dstAccountIds = _attributes.dstAccountIds;
		if (dstAccountIds.length === 0) {
			dstAccountIds = null;
		} else if (dstAccountIds.length === 1){
			dstAccountIds = dstAccountIds[0].id;
		}

		let attributes = {
			srcAccountId: _attributes.srcAccountId,
			date: _attributes.date ? _attributes.date.toString("MM-dd-yyyy") : null,
			transactionDate: _attributes.transactionDate ? _attributes.transactionDate.toString("MM-dd-yyyy") : null,
			description: _attributes.description,
			amount: _attributes.amount,
			dstAccountIds: dstAccountIds,
			other: _attributes.other
		};

		// Remove unneeded data to save space:

		if (attributes.other && attributes.other.length === 0) {
			delete attributes.other;
		}

		for (var key in attributes) {
			if (attributes[key] === null) {
				delete attributes[key];
			}
		}

		return attributes;
	}

}

export class TransactionModels extends AbstractCollection {

	static getInstance () {
		return AppModel.getInstance().get("transactions");
	}

	static getBudgetInstance () {
		if (!budgetInstance) {
			budgetInstance = new TransactionModels();
		}

		return budgetInstance;
	}

	static get ORDER_UPDATED () { return "TransactionModels.ORDER_UPDATED"; }

	get model () { return TransactionModel; }

	/**
	 * Updates the order of the models from earliest to latest,
	 * while maintaining the order each model was already in the array.
	 * @method updateOrder
	 */
	updateOrder () {
		let models = this.models;

		for (let i = 1; i < models.length; i++) {
			let prevModel = models[i - 1];
			let model = models[i];

			if (model.get("date") < prevModel.get("date")) {
				models.splice(i, 1);
				for (let j = i - 1; j >= 0; j--) {
					let _model = models[j];
					if (model.get("date") >= _model.get("date")) {
						models.splice(j + 1, 0, model);
						break;
					} else if (j === 0) {
						models.unshift(model);
						break;
					}
				}
			}
		}

		this.trigger(TransactionModels.ORDER_UPDATED);
	}

	addTransactions (transactions) {
		this.add(transactions);
		this.updateOrder();
	}

	getBalance (endDate = null) {
		let balance = 0,
			transaction;

		for (let l = this.length, i = 0; i < l; i++) {
			transaction = this.at(i);

			if (endDate && transaction.get("date") > endDate) {
				break;
			}

			balance += transaction.get("amount");
		}

		return balance;
	}

	findLastFromAccount (accountIds) {
		let t, models = this.models;

		for (let l = this.length, i = l - 1; i >= 0; i--) {
			t = models[i];

			for (let jl = accountIds.length, j = 0; j < jl; j++) {
				if (t.hasAccount(accountIds[j])) {
					return t;
				}
			}
		}

		return null;
	}

}
