import {AbstractView} from "../../rovert/scripts/AbstractView";
import {AppModel} from "../model/AppModel";

export class MainHeader extends AbstractView {

	events () {
		return {
			"click .mainHeader_saveBtn": "_handleSaveBtnClick"
		}
	}

	constructor (params) {
		super(params);

		this.setTemplate("menu.MainHeader");
	}

	_handleSaveBtnClick (event) {
		AppModel.getInstance().save();
	}

}
