import {DialogModel} from "../model/DialogModel";
import {AbstractDialog} from "./AbstractDialog";
import {ChangeAccountView} from "../view/ChangeAccountView";

export class ChangeAccountDialog extends AbstractDialog {

	get events () {
		return {
		};
	}

	constructor (params) {
		params = params || {};

		params.model = new DialogModel({
			title: "Change Account"
		});

		super(params);

		this.changeAccountView = null;

		this.setTemplate("dialog.ChangeAccountDialog");
	}

	/**
	 * Override.
	 */
	_configUIBeforeReplace () {
		super._configUIBeforeReplace();

		this.changeAccountView = new ChangeAccountView({
			el: this.$(".changeAccountDialog_changeAccountView")
		});
		this.listenTo(this.changeAccountView, ChangeAccountView.ACCOUNT_CHANGED, this._handleAccountChanged);
	}

	setTransaction (transaction, transactionsFilter, useSrcAccount = false) {
		this.changeAccountView.setTransaction(transaction, transactionsFilter, null, useSrcAccount);
	}

	_handleAccountChanged () {
		this.close();
	}

}
