import {AbstractView} from "../../rovert/scripts/AbstractView";

export class LoadView extends AbstractView {

	get events () {
		return {
			"change .loadView_selectFileInput": "_handleSelectFileInputChange"
		};
	}

	constructor (params) {
		super(params);

		this.fileName = null;
		this.fileData = null;

		this.setTemplate("view.LoadView");
	}

	_handleSelectFileInputChange (event) {
		let files = event.target.files,
			file = files[0];

		if (file) {
			this.fileName = file.name;

			let reader = new FileReader();
			reader.addEventListener("progress", this._handleFileProgress.bind(this));
			// reader.addEventListener("abort", this._handleFileAbort.bind(this)); TODO.
			// reader.addEventListener("error", this._handleFileError.bind(this)); TODO.
			reader.addEventListener("load", this._handleFileLoad.bind(this));
			reader.readAsText(file);
		}
	}

	_handleFileProgress (event) {
		var progress = 0;

		if (event.lengthComputable) {
			progress = event.loaded / event.total;
		}

		progress = (progress * 100) | 0;
		progress = `${progress}%`;

		// this.$fileUploadProgress.html(progress);
	}

	_handleFileLoad (event) {
		let fileData = event.target.result;

		try {
			this.fileData = JSON.parse(fileData);
		} catch (error) {
			console.log("ERROR parsing file:", error);
		}

		if (this.fileData) {
			this.model.setDatabase(this.fileData, this.fileName);
		}
	}

}
