import {AbstractModel, AbstractCollection} from "../../rovert/scripts/AbstractModel";
import {AppModel} from "./AppModel";
import {TransactionModels} from "./TransactionModel";
import {BudgetTransactionModels} from "./BudgetTransactionModel";
import {TransactionsFilterModels} from "./TransactionsFilterModel";

export class AccountModel extends AbstractModel {

	defaults () {
		return {
			// id: "1", // [String]
			parentAccountId: null, // [Number]
			name: null, // [String]
			balance: 0, // [Int]

			bankID: null, // [Number]
			ownerIDs: null, // [Array[Number]]

			amountDisplayRatio: 0.01, // [Number] The number to multiply amounts by for their display values.
			transactionsFilter: null // [TransactionsFilterModel]
		};
	}

	constructor (params) {
		super(params);
	}

	getAncestralNames () {
		return AccountModels.getInstance().getAncestralNames(this.id);
	}

	getAncestralName (separator = " » ") {
		return AccountModels.getInstance().getAncestralName(this.id, separator);
	}

	getDescendentsIDs () {
		return AccountModels.getInstance().getDescendentsIDs(this.id);
	}

	getTransactionsFilter (includeDescendentTransactions) {
		let filter = this.get("transactionsFilter");
		let accountIds = [ this.id ];

		if (includeDescendentTransactions) {
			accountIds = accountIds.concat(this.getDescendentsIDs());
		}

		if (filter) {
			filter.set("accountIds", accountIds);
			filter.updateBalance();
		} else {
			filter = TransactionsFilterModels.getInstance().getModel(accountIds);
			this.listenTo(filter, "change:balance", () => {
				this.set("balance", filter.get("balance"));
			});

			this.set("balance", filter.get("balance"));
			this.set("transactionsFilter", filter);
		}

		return filter;
	}

	toJSON () {
		let json = {
			id: this.attributes.id,
			parentAccountId: this.attributes.parentAccountId,
			name: this.attributes.name,
			balance: this.attributes.balance,
			bankID: this.attributes.bankID,
			ownerIDs: this.attributes.ownerIDs
		};

		for (var key in json) {
			if (json[key] === null) {
				delete json[key];
			}
		}

		return json;
	}

}

export class AccountModels extends AbstractCollection {

	static getInstance () {
		return AppModel.getInstance().get("accounts");
	}

	get model () { return AccountModel; }

	getAccountLabel (id) {
		let account = this.get(id);
		return account ? account.get("name") : null;
	}

	forEachAncestor (id, includeSuppliedAccount, func) {
		let account = this.get(id);

		while (account) {
			if (includeSuppliedAccount || account.id !== id) {
				let continueLoop = func(account);
				if (continueLoop === false) {
					break;
				}
			}

			account = this.get(account.get("parentAccountId"));

			if (account && account.id === "0") {
				// Do not include the root account.
				break;
			}
		}
	}

	forEachDescendent (id, includeSuppliedAccount, func) {
		let account = this.get(id);

		while (account) {
			if (includeSuppliedAccount || account.id !== id) {
				let continueLoop = func(account);
				if (continueLoop === false) {
					break;
				}
			}

			account = this.findWhere({
				parentAccountId: account.id
			});
		}
	}

	getAncestralNames (id) {
		let names = [];

		this.forEachAncestor(id, true, (account) => {
			names.push(account.get("name"));
		});

		return names;
	}

	getAncestralName (id, separator = " » ") {
		let names = this.getAncestralNames(id);
		names.reverse();
		return names.join(separator);
	}

	getDescendentsIDs (id, _models) {
		let ids = [];

		if (!_models) {
			_models = this.models;
		}

		for (var i = 0, l = _models.length; i < l; i++) {
			let model = _models[i];
			if (model.get("parentAccountId") === id) {
				ids.push(model.id);
				ids = ids.concat(this.getDescendentsIDs(model.id));
			}
		}

		return ids;
	}

	getIDTree (models = null, parentID = null, _level = 0) {
		let data = [];

		if (models === null) {
			models = this.models.slice(0);
		}

		for (var i = 0, l = models.length; i < l; i++) {
			let model = models[i];
			if (model.get("parentAccountId") === parentID) {
				models.splice(i, 1);

				data.push({
					id: model.cid,
					children: this.getIDTree(models, model.id, _level + 1)
				});

				i = -1;
				l = models.length;
			}
		}

		return data;
	}

	getJSTreeData (numLevelsOpen = Number.POSITIVE_INFINITY, parentID = null) {
		let models = this.models.slice(0);

		let data = this._getJSTreeChildren(models, numLevelsOpen, parentID);

		return data;
	}

	_getJSTreeChildren (models, numLevelsOpen = Number.POSITIVE_INFINITY, parentID = null, _level = 0) {
		let data = [];

		for (var i = 0, l = models.length; i < l; i++) {
			let model = models[i];
			if (model.get("parentAccountId") === parentID) {
				models.splice(i, 1);

				data.push({
					id: model.cid,
					text: model.get("name") + " - " + model.id, // TODO: remove for production.
					state: { opened: _level < numLevelsOpen },
					children: this._getJSTreeChildren(models, numLevelsOpen, model.id, _level + 1)
				});

				i = -1;
				l = models.length;
			}
		}

		return data;
	}

	createNewAccount (name, parentAccountId) {
		if (typeof parentAccountId === "number") {
			parentAccountId = parentAccountId + "";
		}

		let account = new AccountModel({
			id: this.getNewId(),
			name: name,
			parentAccountId: parentAccountId
		});

		this.add(account);
	}

	remove (models, options) {
		if (!_.isArray(models)) { models = [models]; }

		let allTransactions = TransactionModels.getInstance().models;
		let allBudgetTransactions = BudgetTransactionModels.getInstance().models;

		models.forEach(model => {
			model = this.get(model); // Just in case it's an id.

			let l, i, transaction;

			for (l = allTransactions.length, i = 0; i < l; i++) {
				transaction = allTransactions[i];
				transaction.removeSrcAccount(model.id);
				transaction.removeDstAccount(model.id);
			}

			for (l = allBudgetTransactions.length, i = 0; i < l; i++) {
				transaction = allBudgetTransactions[i];
				transaction.removeSrcAccount(model.id);
				transaction.removeDstAccount(model.id);
				transaction.removeDynamicAccount(model.id);
			}

			let childModelsToDelete = [];

			this.forEach(_model => {
				if (_model.get("parentAccountId") === model.id) {
					childModelsToDelete.push(_model);
				}
			});

			this.remove(childModelsToDelete, options);
		});

		super.remove(models, options);
	}

}
