import Backbone from "backbone";

export class AbstractView extends Backbone.View {

	constructor (params) {
		super(params);

		this.params = params;

		this.cleanedUp = false;
		this.template = null;
	}

	getParam (key, defaultValue) {
		return this.params && this.params.hasOwnProperty(key) ? this.params[key] : defaultValue;
	}

	setTemplate  (url) {
		this.template = templates[url];
		this.render();
	}

	render () {
		var $el = $(this.template(this._getTemplateData())),
			previousEl = this.el;

		this.setElement($el);

		this._configUIBeforeReplace();

		if (previousEl.parentElement) {
			previousEl.parentElement.replaceChild(this.el, previousEl);
		}

		this._configUIAfterReplace();

		return this;
	}

	_getTemplateData () {
		return this.model ? this.model.attributes || this.model : null;
	}

	_configUIBeforeReplace () {}

	_configUIAfterReplace () {}

	cleanUp () {
		this.cleanedUp = true;

		this.stopListening();

		if (this.params) {
			for (let key in this.params) {
				delete this.params[key];
			}

			this.params = null;
		}

		this.model = null;
	}

}
