import Backbone from "backbone";
import {AbstractView} from "../../rovert/scripts/AbstractView";

export class ImportTransactionListItem extends AbstractView {

	constructor (params) {
		super(params);

		let transaction = this.params.transaction;
		this.listenTo(transaction, "change:viewLabel", this._handleTransactionAccountLabelChange);
		this.listenTo(transaction, "change:accountConfidence", this._handleTransactionAccountConfidenceChange);

		this.model = new Backbone.Model({
			transaction: transaction.attributes,
			accountConfidence: transaction.getAccountConfidenceText()
		});

		this.$account = null;
		this.$accountConfidence = null;

		this.setTemplate("component.ImportTransactionListItem");
	}

	/**
	 * Override.
	 */
	_configUIBeforeReplace () {
		this.$account = this.$(".transactionListItem_account");
		this.$accountConfidence = this.$(".transactionListItem_accountConfidence");

		this._renderAccountConfidenceColour();
	}

	_renderAccountConfidenceColour () {
		let accountConfidence = this.params.transaction.get("accountConfidence");
		let colour = "";

		if (accountConfidence !== null) {
			colour = chroma.scale(["red", "lime"]).mode("lab")(accountConfidence).css();
		}

		this.$accountConfidence[0].style.backgroundColor = colour;
	}

	_handleTransactionAccountLabelChange () {
		this.$account.text(this.params.transaction.get("viewLabel"));
	}

	_handleTransactionAccountConfidenceChange () {
		this.$accountConfidence.text(this.params.transaction.getAccountConfidenceText());
		this._renderAccountConfidenceColour();
	}

}
