import {ServerDelegate} from "../net/ServerDelegate";

export class AbstractRequest {

	static get GET () { return "GET"; }
	static get POST () { return "POST"; }

	constructor (url, data, method) {
		_.extend(this, Backbone.Events);

		this.url = url;
		this.data = data;
		this.method = method || AbstractRequest.GET;

		this.crossDomain = null;

		this.timeout = null;
	}

	getData () {
		return this.data;
	}

	getDataString () {
		var dataString = "",
			urlParams = [];

		if (this.urlParams) {
			var data = this.getData();
			for (var key in data) {
				urlParams.push(key + "=" + data[key]);
			}
		}

		dataString = urlParams.join("&");

		return dataString;
	}

	send () {
		ServerDelegate.sendRequest(this);
	}

	handleSuccess (response) {
		this.trigger("success", this, response);
	}

	handleError (response) {
		this.trigger("error", this, response);
	}

}
