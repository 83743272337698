export class DateUtils {

	static get REPEATS () {
		return {
			DAILY: 1,
			WEEKLY: 2,
			MONTHLY: 3,
			YEARLY: 4
		};
	}

	static get REPEATS_BY_MAP () {
		return {
			1: null,
			2: "REPEATS_WEEKLY_BY",
			3: "REPEATS_MONTHLY_BY",
			4: "REPEATS_YEARLY_BY"
		};
	}

	static get REPEATS_WEEKLY_BY () {
		return {
			SUN: 0,
			MON: 1,
			TUE: 2,
			WED: 3,
			THU: 4,
			FRI: 5,
			SAT: 6
		};
	}

	static get REPEATS_MONTHLY_BY () {
		return {
			DAY_OF_MONTH: 1, // E.g. Every 24th of a month.
			DAY_OF_WEEK: 2, // E.g. Every 2nd Saturday of a month.
			DAYS_FROM_END_OF_MONTH: 3, // E.g. Every 2 days from the end of a month.
			WEEKDAYS_FROM_END_OF_MONTH: 4, // E.g. Every 1 weekday from the end of a month.
			WEEKDAYS_FROM_START_OF_MONTH: 5 // E.g. Every 15 weekdays from the start of a month.
		};
	}

	static get REPEATS_YEARLY_BY () {
		return {
			DAY_OF_MONTH: DateUtils.REPEATS_MONTHLY_BY.DAY_OF_MONTH,
			DAY_OF_WEEK: DateUtils.REPEATS_MONTHLY_BY.DAY_OF_WEEK,
			DAYS_FROM_END_OF_MONTH: DateUtils.REPEATS_MONTHLY_BY.DAYS_FROM_END_OF_MONTH,
			// DAY_OF_YEAR: 4, // E.g. Every 252nd day of a year.
			// DAYS_FROM_END_OF_YEAR: 5 // E.g. Every 51 days from the end of a year.
		};
	}

	static getNextOccurrence (data, dates = null, _tempData = null) {
		let tryCount = _tempData && _tempData.hasOwnProperty("_try") ? _tempData._try : 1;

		if (tryCount >= 100) {
			// Tried a number of times to find the next occurrence, but failed.
			return null;
		}

		if (dates !== null && !Array.isArray(dates)) {
			dates = [dates];
		}

		if (!dates) { dates = []; }

		if (data.endNumOccurrences >= 1 && dates.length === data.endNumOccurrences) {
			// The max num occurrences has been reached.
			return null;
		}

		let date = dates[dates.length - 1] || null;

		if (!date) { date = data.startDate; }
		if (!date) { date = new Date(); }
		if (data.startDate && data.startDate > date) { date = data.startDate; }

		date = new Date(date); // Make a new instance so the original doesn't change.

		// console.log("getNextOccurrence", data, date, _tempData);

		let next = dates.length === 0 ? date : null,
			l, i;

		// See if there's a next repeated item:

		let repeats = _tempData && _tempData.hasOwnProperty("repeats") ? _tempData.repeats : data.repeats;

		if (next === null && repeats) {
			let REPEATS = DateUtils.REPEATS;
			let REPEATS_WEEKLY_BY = DateUtils.REPEATS_WEEKLY_BY;
			let REPEATS_MONTHLY_BY = DateUtils.REPEATS_MONTHLY_BY;
			let REPEATS_YEARLY_BY = DateUtils.REPEATS_YEARLY_BY;

			let every = _tempData && _tempData.hasOwnProperty("every") ? _tempData.every : data.every;
			let by = _tempData && _tempData.hasOwnProperty("by") ? _tempData.by : data.by;

			if (by !== undefined && !Array.isArray(by)) {
				by = [by];
			}

			let dateYear = date.getFullYear(),
				dateMonth = date.getMonth(),
				dateDate = date.getDate(),
				dateDay = date.getDay();

			switch (repeats) {
				case REPEATS.DAILY:
					date.setDate(dateDate + every);
					next = date;
					break;

				case REPEATS.WEEKLY:
					if (by === undefined) {
						by = [dateDay];
					}

					let nextBy = -1;
					// TODO: convert nextBy into nextBys array and populate with all possibilities...
					//       need to do this in case days are out of order.

					for (l = by.length, i = 0; i < l; i++) {
						let thisBy = by[i],
							thisByType = typeof thisBy === "number" ? thisBy : thisBy.type;

						if (thisByType > dateDay) {
							nextBy = thisByType;
							break;
						}
					}

					if (nextBy >= 0) {
						date.setDate(dateDate + (nextBy - dateDay));
					} else {
						let firstBy = by[0],
							firstByType = typeof firstBy === "number" ? firstBy : firstBy.type;

						date.setDate((dateDate + (every * 7) + firstByType) - dateDay);
					}

					next = date;
					break;

				case REPEATS.MONTHLY:
					if (by === undefined) {
						by = [REPEATS_MONTHLY_BY.DAY_OF_MONTH];
					}

					let monthlyNextBys = [];

					for (l = by.length, i = 0; i < l; i++) {
						let thisBy = by[i],
							thisByType = typeof thisBy === "number" ? thisBy : thisBy.type,
							thisByValue = typeof thisBy === "number" ? null : thisBy.value,
							thisByDate = new Date(date);

						switch (thisByType) {
							case REPEATS_MONTHLY_BY.DAY_OF_MONTH:
								if (thisByValue === null) { thisByValue = dateDate; }

								thisByDate.setDate(1);
								thisByDate.setMonth(dateMonth + every);
								thisByDate.setDate(thisByValue);

								var checkMonth = new Date(date);
								checkMonth.setDate(1);
								checkMonth.setMonth(checkMonth.getMonth() + every);

								if (thisByDate.getMonth() !== checkMonth.getMonth()) {
									// This date doesn't exist for this month, so skip it.
									thisByDate.setDate(0);
									thisByDate = DateUtils.getNextOccurrence(data, thisByDate, {
										_try: tryCount + 1,
										by: [{ type: thisByType, value: thisByValue }]
									});
								}

								break;
							case REPEATS_MONTHLY_BY.DAY_OF_WEEK:
								if (thisByValue === null) {
									thisByValue = {
										day: dateDay,
										occurrence: Math.ceil(dateDate / 7)
									};
								}

								thisByDate.setDate(1);
								thisByDate.setMonth(dateMonth + every);

								let thisByDateMonth = thisByDate.getMonth(),
									thisByDateDay = thisByDate.getDay(),
									dayDiff = thisByValue.day - thisByDateDay;

								if (dayDiff < 0) { dayDiff += 7 };

								thisByDate.setDate(1 + dayDiff + (7 * (thisByValue.occurrence - 1)));

								// TODO: check for year too?
								if (thisByDateMonth !== thisByDate.getMonth()) {
									// This date doesn't exist for this month, so skip it.
									thisByDate.setDate(0);
									thisByDate = DateUtils.getNextOccurrence(data, thisByDate, {
										_try: tryCount + 1,
										by: [{ type: thisByType, value: thisByValue }]
									});
								}

								break;
							case REPEATS_MONTHLY_BY.DAYS_FROM_END_OF_MONTH:
							case REPEATS_MONTHLY_BY.WEEKDAYS_FROM_END_OF_MONTH:
								let tempDate = null;

								if (thisByValue === null) {
									let dateMonthEnd = new Date(date);
									dateMonthEnd.setDate(1);
									dateMonthEnd.setMonth(dateMonthEnd.getMonth() + 1);
									dateMonthEnd.setDate(0);
									thisByValue = 1 + dateMonthEnd.getDate() - dateDate;

									if (thisByType === REPEATS_MONTHLY_BY.WEEKDAYS_FROM_END_OF_MONTH) {
										tempDate = new Date(dateMonthEnd);
										while (tempDate >= date) {
											let day = tempDate.getDay();
											if (day === REPEATS_WEEKLY_BY.SUN || day === REPEATS_WEEKLY_BY.SAT) {
												thisByValue--;
											}
											tempDate.setDate(tempDate.getDate() - 1);
										}
									}
								}

								thisByDate.setDate(1);
								thisByDate.setMonth(dateMonth + (dates.length > 0 ? every : 0) + 1);

								if (thisByType === REPEATS_MONTHLY_BY.DAYS_FROM_END_OF_MONTH) {
									thisByDate.setDate(1 - thisByValue);
								} else {
									let tempValue = 1;
									thisByDate.setDate(0);

									while (true) {
										let day = thisByDate.getDay();
										if (day === REPEATS_WEEKLY_BY.SUN || day === REPEATS_WEEKLY_BY.SAT) {
											thisByDate.setDate(thisByDate.getDate() - 1);
										} else if (tempValue === thisByValue) {
											break;
										} else {
											thisByDate.setDate(thisByDate.getDate() - 1);
											tempValue++;
										}
									}
								}

								var checkMonth = new Date(date);
								checkMonth.setDate(1);
								checkMonth.setMonth(checkMonth.getMonth() + (dates.length > 0 ? every : 0));

								if (thisByDate.getMonth() !== checkMonth.getMonth()) {
									// This month doesn't have enough days to look back on, so skip it.
									thisByDate.setDate(1);
									thisByDate.setMonth(dateMonth + every);
									thisByDate = DateUtils.getNextOccurrence(data, thisByDate, {
										_try: tryCount + 1,
										by: [{ type: thisByType, value: thisByValue }]
									});
								} else if (thisByDate < date) {
									// An edge-case was encountered that only happens when dates.length === 0.
									thisByDate = DateUtils.getNextOccurrence(data, thisByDate, {
										_try: tryCount + 1,
										by: [{ type: thisByType, value: thisByValue }]
									});
								}

								break;
						}

						monthlyNextBys.push(thisByDate);
					}

					monthlyNextBys.sort((a, b) => a - b);

					next = monthlyNextBys[0];

					break;

				case REPEATS.YEARLY:
					if (by === undefined) {
						by = [REPEATS_YEARLY_BY.DAY_OF_YEAR];
					}

					let yearlyNextBys = [];

					for (l = by.length, i = 0; i < l; i++) {
						let thisBy = by[i],
							thisByType = typeof thisBy === "number" ? thisBy : thisBy.type,
							thisByValue = typeof thisBy === "number" ? null : thisBy.value,
							thisByDate = new Date(date);

						switch (thisByType) {
							case REPEATS_YEARLY_BY.DAY_OF_MONTH:
							case REPEATS_YEARLY_BY.DAY_OF_WEEK:
							case REPEATS_YEARLY_BY.DAYS_FROM_END_OF_MONTH:
								thisByDate = DateUtils.getNextOccurrence(data, thisByDate, {
									repeats: REPEATS.MONTHLY,
									every: every * 12,
									by: [{ type: thisByType, value: thisByValue }]
								});
								break;

							/* Not bothering with these use-cases for now.
							case REPEATS_YEARLY_BY.DAY_OF_YEAR:
								if (thisByValue === null) {
									let dateYearStart = new Date(date);
									dateYearStart.setDate(1);
									dateYearStart.setMonth(0);

									thisByValue = 1;

									while (dateYearStart.getDate() !== dateDate && dateYearStart.getMonth() !== dateMonth) {
										dateYearStart.setDate(dateYearStart.getDate() + 1);
										thisByValue++;
									}
								}

								// TODO.

								break;

							case REPEATS_YEARLY_BY.DAYS_FROM_END_OF_YEAR:
								// TODO.
								if (thisByValue === null) {
									let dateYearEnd = new Date(date);
									dateYearEnd.setDate(1);
									dateYearEnd.setMonth(11);
									dateYearEnd.setDate(31);
									// thisByValue = dateYearEnd.getDate() - dateDate;
								}

								break;
							*/
						}

						yearlyNextBys.push(thisByDate);
					}

					yearlyNextBys.sort((a, b) => a - b);

					next = yearlyNextBys[0];

					break;
			}
		}

		if (!next || (data.endDate && next > data.endDate)) {
			next = null;
		}

		// console.log("getNextOccurrence=", next);

		return next;
	}

	static normalizeDate (date, clone = true) {
		if (date == null) { return date; }

		if (clone) {
			date = new Date(date);
		}

		date.setMilliseconds(0);
		date.setSeconds(0);
		date.setMinutes(0);
		date.setHours(0);

		return date;
	}

}
