import {AbstractModel, AbstractCollection} from "../../rovert/scripts/AbstractModel";

export class BankModel extends AbstractModel {

	defaults () {
		return {
			// id: -1,
			name: null // [String]
		};
	}

	constructor (params) {
		super(params);
	}

}

export class BankModels extends AbstractCollection {

	get model () { return BankModel; }

}
