import {AbstractView} from "../../rovert/scripts/AbstractView";
import {SimpleTemplateView} from "../../rovert/scripts/SimpleTemplateView";
import {AccountModels} from "../model/AccountModel";
import {TransactionAccountModels} from "../model/TransactionAccountModel";
import {ImportTransactionListItem} from "../component/ImportTransactionListItem";

export class ChangeAccountView extends AbstractView {

	static get ACCOUNT_CHANGED () { return "ChangeAccountView.ACCOUNT_CHANGED"; }

	get events () {
		return {
			"input .splitAccountListItem_amount": "_handleSplitAccountItemInput",
			"click .changeAccountView_addSplitAccount": "_addSplitAccount",
			"click .changeAccountView_doneSplitBtn": "_handleDoneSplitBtnClick"
		};
	}

	constructor (params) {
		super(params);

		this.$transaction = null;
		this.transaction = null;
		this.useSrcAccount = null;
		this.$accountGuesses = null;
		this.$accounts = null;
		this.accountsTree = null;

		this.$splitAccounts = null;
		this.$splitAccountsDoneBtn = null;
		this.$splitAccountsError = null;

		this.setTemplate("view.ChangeAccountView");
	}

	setTransaction (transaction, transactionsFilter, accountMatches = null, useSrcAccount = false) {
		this.transaction = transaction;
		this.useSrcAccount = useSrcAccount;

		this.$(".changeAccountView_split").toggleClass("hidden", useSrcAccount);

		let allAccounts = AccountModels.getInstance();

		// Add transaction to top:

		let item = new ImportTransactionListItem({
			transaction: transaction,
			el: this.$transaction
		});

		this.$transaction = item.$el;

		// Add transaction account guesses:

		if (!transactionsFilter) {
			accountMatches = [];
		} else if (!accountMatches) {
			accountMatches = TransactionAccountModels.getTransactionMatches(transaction, transactionsFilter, useSrcAccount);
		}

		var $headers = this.$accountGuesses.children().first();
		this.$accountGuesses.empty();
		this.$accountGuesses.append($headers);

		accountMatches.forEach(accountMatch => {
			let accountConfidenceText = ((accountMatch.score * 100) | 0) + "%";

			let account = allAccounts.get(accountMatch.accountId);

			let item = new SimpleTemplateView({
				template: "component.AccountGuessListItem",
				model: new Backbone.Model({
					name: account.getAncestralName(),
					accountConfidence: accountConfidenceText
				}),
				events: {
					"click .accountGuessListItem_select": this._handleChooseAccountSelectBtnClick.bind(this, transaction, accountMatch)
				}
			});

			this.$accountGuesses.append(item.el);
		});

		// Add accounts:
		let data = allAccounts.getJSTreeData(1);

		this.$accounts.jstree({
			core: {
				data: data,
				multiple: false
			}
		});

		// TD: weird that you have to call jstree() again in order to get the instance:
		this.accountsTree = this.$accounts.jstree();
	}

	_configUIBeforeReplace () {
		this.$transaction = this.$(".changeAccountView_transaction");
		this.$accountGuesses = this.$(".changeAccountView_guesses");
		this.$accounts = this.$(".changeAccountView_accounts");

		this.$accounts.on("dblclick", ".jstree-anchor", this._handleChooseAccountTreeItemDoubleClick.bind(this));

		this.$splitAccounts = this.$(".changeAccountView_splitAccounts");
		this.$splitAccountsDoneBtn = this.$(".changeAccountView_doneSplitBtn");
		this.$splitAccountsError = this.$(".changeAccountView_error");

		this._addSplitAccount();
		this._addSplitAccount();
	}

	_setChooseAccountAccount (accountId) {
		let selectedAccount = AccountModels.getInstance().get(accountId);

		if (selectedAccount) {
			if (this.useSrcAccount) {
				this.transaction.set("srcAccountId", selectedAccount.id);
			} else {
				this.transaction.setDstAccountIds(selectedAccount.id);
			}

			this.transaction.set("accountConfidence", 1);
		}

		this.trigger(ChangeAccountView.ACCOUNT_CHANGED);
	}

	_handleChooseAccountSelectBtnClick (transaction, accountMatch, event) {
		this._setChooseAccountAccount(accountMatch.accountId);
	}

	_handleChooseAccountTreeItemDoubleClick (event) {
		let nodeID = event.target.id.replace("_anchor", "");
		if (nodeID) {
			this._setChooseAccountAccount(nodeID);
		}
	}

	_handleSplitAccountItemInput (event) {
		let total = 0;

		let $splitAccountListItemAmounts = this.$(".splitAccountListItem_amount");
		$splitAccountListItemAmounts.each((i, itemEl) => {
			total += (Number.parseFloat(itemEl.value, 10) / this.transaction.get("amountDisplayRatio")) || 0;
		});

		let showError = total != this.transaction.get("amount");

		this.$splitAccountsError.css("display", showError ? "" : "none");
		this.$splitAccountsDoneBtn.attr("disabled", showError);
	}

	_addSplitAccount () {
		let splitAccount = new SimpleTemplateView({
			template: "component.SplitAccountListItem"
		});

		this.$splitAccounts.append(splitAccount.el);
	}

	_handleDoneSplitBtnClick (event) {
		if (this.$splitAccountsDoneBtn.attr("disabled")) { return; }

		let accountIds = [];

		let $splitAccountListItems = this.$(".splitAccountListItem");
		$splitAccountListItems.each((i, itemEl) => {
			accountIds.push({
				id: $(itemEl).find(".splitAccountListItem_account").val(),
				amount: Number.parseFloat($(itemEl).find(".splitAccountListItem_amount").val(), 10)
			});
		});

		this.transaction.setDstAccountIds(accountIds);

		this.trigger(ChangeAccountView.ACCOUNT_CHANGED);
	}

}
