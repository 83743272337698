import {NumberUtils} from "../../rovert/scripts/NumberUtils";
import {Utils} from "../../rovert/scripts/Utils";
import {AbstractModel, AbstractCollection} from "../../rovert/scripts/AbstractModel";
import {TransactionModels} from "./TransactionModel";

let instance;

export class TransactionsFilterModel extends AbstractModel {

	defaults () {
		return {
			// Filters:
			accountIds: null, // [Array]
			dateStart: null, // [Date]
			dateEnd: null, // [Date]

			// Data:
			balance: 0, // [Number] Note: does not include budgetTransactions.
			transactions: new TransactionModels(), // [TransactionModels]
			budgetTransactions:  new TransactionModels(), // [TransactionModels]
		};
	}

	constructor (params) {
		super(params);

		this.on("change:transactions", this._handleTransactionsChanged, this);

		this.on("change:accountIds", this._updateTransactions, this);
		this.on("change:dateStart", this._updateTransactions, this);
		this.on("change:dateEnd", this._updateTransactions, this);
		this._updateTransactions();

		this.on("change:transactions", this.updateBalance, this);
		this.on("change:budgetTransactions", this.updateBalance, this);
		this.updateBalance();

		let allTransactions = TransactionModels.getInstance();
		this.listenTo(allTransactions, "add", this._addTransaction.bind(this, false));
		this.listenTo(allTransactions, "remove", this._removeTransaction.bind(this, false));
		this.listenTo(allTransactions, TransactionModels.ORDER_UPDATED, this._updateTransactions);

		let allBudgetTransactions = TransactionModels.getBudgetInstance();
		this.listenTo(allBudgetTransactions, "add", this._addTransaction.bind(this, true));
		this.listenTo(allBudgetTransactions, "remove", this._removeTransaction.bind(this, true));
		this.listenTo(allBudgetTransactions, TransactionModels.ORDER_UPDATED, this._updateTransactions);
	}

	_handleTransactionsChanged (model, value, options) {
		let previous = this.previous("transactions");
		if (previous) {
			this.stopListening(previous);
		}

		if (value) {
			this.listenTo(value, "change:amount", this.updateBalance);
		}
	}

	_updateTransactions () {
		let accountIds = this.get("accountIds");
		let dateStart = this.get("dateStart");
		let dateEnd = this.get("dateEnd");

		let allTransactions = TransactionModels.getInstance();
		let allBudgetTransactions = TransactionModels.getBudgetInstance();

		let filteredTransactions = _.filter(allTransactions.models, (transaction) => {
			let included = this._isTransactionIncluded(transaction, accountIds, dateStart, dateEnd);
			return included;
		});

		let filteredBudgetTransactions = _.filter(allBudgetTransactions.models, transaction => {
			let included = this._isTransactionIncluded(transaction, accountIds, dateStart, dateEnd);
			return included;
		});

		this.set({
			transactions: new TransactionModels(filteredTransactions),
			budgetTransactions: new TransactionModels(filteredBudgetTransactions)
		});
	}

	_isTransactionIncluded (transaction, _accountIds, _dateStart, _dateEnd) {
		let included = true;

		if (_accountIds === undefined) {
			_accountIds = this.get("accountIds");
			_dateStart = this.get("dateStart");
			_dateEnd = this.get("dateEnd");
		}

		if (_accountIds !== null) {
			let hasAccount = false;

			for (let i = 0, l = _accountIds.length; i < l; i++) {
				hasAccount = transaction.hasAccount(_accountIds[i]);

				if (hasAccount) { break; }
			}

			included = hasAccount;
		}

		if (included && _dateStart !== null) {
			included = transaction.get("date") >= _dateStart;
		}

		if (included && _dateEnd !== null) {
			included = transaction.get("date") >= _dateEnd;
		}

		return included;
	}

	updateBalance () {
		let transactions = this.get("transactions"),
			budgetTransactions = this.get("budgetTransactions"),
			balance = 0;

		transactions.forEach(transaction => {
			balance += transaction.get("amount");
			balance = NumberUtils.toFixed(balance, 2);

			transaction.fastSet("balance", balance);
		});

		this.set("balance", balance);

		budgetTransactions.forEach(transaction => {
			balance += transaction.get("amount");
			balance = NumberUtils.toFixed(balance, 2);

			// TODO: remove this check once amounts are converted from dollars to cents. Probably won't need it.
			if (balance === -0) {
				balance = 0;
			}

			transaction.set("balance", balance);
		});
	}

	getBalance (endDate = null, endDateIncludesBudgetTransactions = false) {
		let balance = null;

		if (endDate) {
			balance = this.get("transactions").getBalance(endDate);

			if (endDateIncludesBudgetTransactions && this.get("budgetTransactions")) {
				balance += this.get("budgetTransactions").getBalance(endDate);
			}
		} else {
			balance = this.get("balance");
		}

		return balance;
	}

	_addTransaction (isBudgetTransaction = false, transaction) {
		let included = this._isTransactionIncluded(transaction);

		if (included) {
			this.get(isBudgetTransaction ? "budgetTransactions" : "transactions").add(transaction);
			this.updateBalance();
		}
	}

	_removeTransaction (isBudgetTransaction = false, transaction) {
		this.get(isBudgetTransaction ? "budgetTransactions" : "transactions").remove(transaction);
	}

	cleanUp () {
		super.cleanUp();

		this.set({
			transactions: null,
			budgetTransactions: null,
		});

		TransactionsFilterModels.getInstance().remove(this);
	}

}

export class TransactionsFilterModels extends AbstractCollection {

	static getInstance () {
		if (!instance) {
			instance = new TransactionsFilterModels();
		}

		return instance;
	}

	get model () { return TransactionsFilterModel; }

	getModel (accountIds) {
		if (typeof accountIds === "string") {
			accountIds = [accountIds];
		}

		let model = this.find(model => {
			return Utils.isSameArray(model.get("accountIds"), accountIds);
		});

		if (!model) {
			model = new TransactionsFilterModel({
				accountIds: accountIds
			});

			this.add(model);
		}

		return model;
	}

}
