import {AbstractDialog} from "../dialog/AbstractDialog";

let instance = null;

export class DialogManager {

	static getInstance () {
		if (!instance) {
			instance = new DialogManager();
		}

		return instance;
	}

	static open (...args) { return DialogManager.getInstance().open(...args); }
	static close (...args) { return DialogManager.getInstance().close(...args); }

	constructor (params) {
		_.extend(this, Backbone.Events);

		this.$el = null;
		this.$modal = null;
		this.$dialogs = null;
		this.currentDialog = null;

		this.modalLocked = false;
	}

	initialize (params) {
		this.$el = $(params.el);
		this.$modal = this.$el.find("#dialogs_modal");
		this.$dialogs = this.$el.find("#dialogs_wrap");

		this.$modal.click(this._handleModalClick.bind(this));
	}

	toggleModal (isShowing) {
		this.$modal.toggleClass("in", isShowing);
	}

	open (dialog) {
		if (this.currentDialog) {
			this.close(this.currentDialog);
		}

		this.currentDialog = dialog;
		this.$dialogs.append(this.currentDialog.el);

		this.toggleModal(true);
	}

	close (dialog) {
		dialog.cleanUp();
		dialog.remove();

		if (dialog === this.currentDialog) {
			this.currentDialog = null;
			this.toggleModal(false);
		}
	}

	_handleModalClick (event) {
		if (!this.modalLocked) {
			this.toggleModal(false);

			this.close(this.currentDialog);
		}
	}

}
