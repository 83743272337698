import {TransactionModels} from "../model/TransactionModel";
import {AppModel} from "../model/AppModel";
import {AbstractView} from "../../rovert/scripts/AbstractView";
import {TransactionListItem} from "../component/TransactionListItem";
import {ImportDialog} from "../dialog/ImportDialog";
import {TransactionEventsManager} from "../manager/TransactionEventsManager";

export class AccountTransactionsView extends AbstractView {

	get events () {
		return {
			"change #accountTransactionsView_includeDescendentTransactions": "_handleIncludeDescendentTransactionsChange",
			"click .accountTransactionsView_importBtn": "_handleImportBtnClick"
		};
	}

	constructor (params) {
		super(params);

		AppModel.getInstance().updateBudgetTransactions();

		this.transactionsFilter = null;

		this.transactionEventsManager = new TransactionEventsManager();

		this.$includeDescendentTransactions = null;
		this.$transactions = null;
		this.$budgetTransactions = null;

		this.setTemplate("view.AccountTransactionsView");
	}

	_configUIBeforeReplace () {
		this.$includeDescendentTransactions = this.$("#accountTransactionsView_includeDescendentTransactions");
		this.$transactions = this.$(".accountTransactionsView_transactions");
		this.$budgetTransactions = this.$(".accountTransactionsView_budgetTransactions");

		this._renderTransactions();
	}

	_renderTransactions () {
		let includeDescendentTransactions = this.$includeDescendentTransactions.get(0).checked;
		this.transactionsFilter = this.model.getTransactionsFilter(includeDescendentTransactions);
		this.transactionsFilter.updateBalance();

		this.transactionEventsManager.transactionsFilter = this.transactionsFilter;

		this.$transactions.children().not(".transactionListItem-header").remove();
		this.$budgetTransactions.empty();

		var transactions = this.transactionsFilter.get("transactions");
		transactions.forEach((transaction) => {
			transaction.updateAccountLabel(this.transactionsFilter.get("accountIds"));
			this._addTransaction(transaction);
		});

		let budgetTransactions = this.transactionsFilter.get("budgetTransactions");
		if (budgetTransactions) {
			budgetTransactions.forEach(transaction => {
				transaction.updateAccountLabel(this.transactionsFilter.get("accountIds"));
				this._addBudgetTransaction(transaction);
			});
		}
	}

	_addTransaction (transaction, updateTable) {
		let item = new TransactionListItem({
			model: transaction,
			fieldsDirectlyEditable: true
		});

		this.transactionEventsManager.addTransaction(item);

		this.$transactions.append(item.el);
	}

	_addBudgetTransaction (transaction) {
		let item = new TransactionListItem({
			model: transaction
		});

		this.$budgetTransactions.append(item.el);
	}

	_handleTransactionAdded (transaction, transactions, options) {
		this._addTransaction(transaction);
	}

	_handleIncludeDescendentTransactionsChange (event) {
		this._renderTransactions();
	}

	_configUIAfterReplace () {
		setImmediate(() => {
			window.scrollTo(0, document.body.scrollHeight);
		});
	}

	_handleImportBtnClick (event) {
		var dialog = new ImportDialog({
			account: this.model,
			transactionsFilter: this.transactionsFilter
		});
		this.listenTo(dialog, "complete", this._handleImportDialogComplete.bind(this, dialog));
		dialog.open();
	}

	_handleImportDialogComplete (dialog, importTransactions) {
		this.stopListening(dialog);

		TransactionModels.getInstance().addTransactions(importTransactions.models);

		AppModel.getInstance().updateBudgetTransactions();

		this._renderTransactions();
	}

	cleanUp () {
		super.cleanUp();

		this.transactionEventsManager.cleanUp();
		this.transactionEventsManager = null;
	}

}
