import {DateUtils} from "../../rovert/scripts/DateUtils";
import {TransactionListItem} from "../component/TransactionListItem";
import {ChangeAccountDialog} from "../dialog/ChangeAccountDialog";
import {BudgetAmountDialog} from "../dialog/BudgetAmountDialog";

export class TransactionEventsManager {

	constructor (params) {
		_.extend(this, Backbone.Events);

		this.transactionsFilter = null;

		this.transactionListItems = [];
	}

	addTransaction (transactionListItem) {
		this.transactionListItems.push(transactionListItem);

		this.listenTo(transactionListItem, TransactionListItem.FIELD_CLICKED, this._handleTransactionFieldClicked);
	}

	_handleTransactionFieldClicked (transactionListItem, fieldEl, fieldType, fieldProp) {
		if (transactionListItem.fieldsDirectlyEditable) {
			let dialog, options;

			switch (fieldType) {
				case "text":
					transactionListItem.editTextField(fieldEl, fieldProp);
					break;
				case "date":
					transactionListItem.editDateField(fieldEl, fieldProp);
					break;
				case "accounts":
					dialog = new ChangeAccountDialog();
					dialog.setTransaction(transactionListItem.model, this.transactionsFilter, fieldProp === "srcAccountId");
					dialog.open();
					break;
				case "amount":
					transactionListItem.editTextField(fieldEl, fieldProp, /^-?\d+(\.\d*)?$/, true);
					break;
				case "int":
					transactionListItem.editTextField(fieldEl, fieldProp, /^-?\d+$/, true);
					break;
				case "positiveInt":
					transactionListItem.editTextField(fieldEl, fieldProp, /^\d+$/, true);
					break;
				case "repeats":
					options = [{ value: "", label: "--none--" }];

					Object.entries(DateUtils.REPEATS).forEach(([key, value]) => {
						options.push({
							value: value,
							label: key
						});
					});

					transactionListItem.editDropdownField(fieldEl, fieldProp, options, value => {
						value = value !== "" ? parseFloat(value) : null;

						if (value !== transactionListItem.model.get(fieldProp)) {
							if (value === null) {
								transactionListItem.model.set("repeatsEvery", null);
								transactionListItem.model.set("repeatsByValue", null);
							}

							transactionListItem.model.set("repeatsBy", null);
						}

						return value;
					});
					break;
				case "repeatsBy":
					let repeatsValue = 	transactionListItem.model.get("repeats"),
						repeatsByOptions = DateUtils[DateUtils.REPEATS_BY_MAP[repeatsValue]];

					options = [{ value: "", label: "--none--" }];

					Object.entries(repeatsByOptions).forEach(([key, value]) => {
						options.push({
							value: value,
							label: key
						});
					});

					transactionListItem.editDropdownField(fieldEl, fieldProp, options, value => {
						value = value !== "" ? parseFloat(value) : null;

						if (value === null) {
							transactionListItem.model.set("repeatsByValue", null);
						}

						return value;
					});
					break;
				case "budgetAmount":
					dialog = new BudgetAmountDialog();
					dialog.setTransaction(transactionListItem.model);
					dialog.open();
					break;
			}
		}
	}

	cleanUp () {
		this.stopListening();

		this.transactionListItems = null;
	}

}
