import {AbstractView} from "../../rovert/scripts/AbstractView";

export class DialogActionBtn extends AbstractView {

	get events () {
		return {
			"click": "_handleClick"
		};
	}

	constructor (params) {
		super(params);

		this.setTemplate("component.DialogActionBtn");

		this.listenTo(this.model, "change:disabled", this._updateDisabled);
	}

	_updateDisabled () {
		if (this.model.get("disabled")) {
			this.$el.attr("disabled", "disabled");
		} else {
			this.$el.removeAttr("disabled");
		}
	}

	_handleClick (event) {
		if (this.model.get("disabled")) { return; }
		this.trigger("click", this);
	}

}
