import { AbstractCollection } from "../../rovert/scripts/AbstractModel";

// TODO: confirm if this logic should stay as a model or moved to some other model/util.

export class TransactionAccountModels extends AbstractCollection {

	/**
	 * Get matches for the specified transaction.
	 * @method getTransactionMatches
	 * @param  {TransactionModel} transaction
	 * @return {Array} Assume their order is correct.
	 */
	static getTransactionMatches (transaction, transactionsFilter, useSrcAccount = false) {
		let tDescription = transaction.get("description"),
			tDate = transaction.get("date"),
			tAmount = transaction.get("amount");

		let transactions = transactionsFilter.get("transactions");

		// Earliest date is 1.5 years in the past.
		// TODO: make the years configurable by user.
		let earliestDate = new Date(tDate.getTime() - (1000 * 60 * 60 * 24 * (365 * 1.5)));

		let matches = [];
		let exactMatches = [];
		let exactMatchesHash = {};
		let accountScores = {};
		let totalMatches = 0;

		for (let l = transactions.length, i = l - 1; i >= 0; i--) {
			let _transaction = transactions.at(i);

			if (_transaction === transaction) { continue; }

			let _tDate = _transaction.get("date");

			// Only check transactions that occurred after the earliestDate or before the provided one:
			if (_tDate > tDate) { continue; }
			if (_tDate < earliestDate) { break; }

			let descriptionScore = this.getDescriptionScore(tDescription, _transaction.get("description"));

			// Only consider transactions with a decent descriptionScore:
			// TODO: make this number configurable by the user.
			if (descriptionScore < 0.5) { continue; }

			// TODO: get date score: day of week, month, year.
			// TODO: frequency score: based on the description, price?, date?, how often is it this account?

			let _accountIds, _account, _id;

			if (useSrcAccount) {
				_accountIds = [{
					id: _transaction.get("srcAccountId"),
					amount: _transaction.get("amount")
				}];
			} else {
				_accountIds = _transaction.get("dstAccountIds");
			}

			for (let jl = _accountIds.length, j = 0; j < jl; j++) {
				_account = _accountIds[j];
				_id = _account.id;

				let amountScore = this.getAmountScore(tAmount, _account.amount);

				// Add the subMatch:

				if (exactMatchesHash[_id]) { continue; }

				if (descriptionScore === 1 && amountScore === 1) {
					let accountScore = accountScores[_id];
					if (accountScore) {
						totalMatches -= accountScore.numMatches;
						delete accountScores[_id];
					}

					// If it's an exact match, add it to the exactMatches list:
					exactMatches.push({
						accountId: _id,
						score: 1
					});

					exactMatchesHash[_id] = true;
				} else {
					let score = (descriptionScore * (2/3)) + (amountScore * (1/3));
					let accountScore = accountScores[_id];

					if (!accountScore) {
						accountScore = {
							numMatches: 0,
							score: 0
						};
						accountScores[_id] = accountScore;
					}

					accountScore.numMatches++;
					totalMatches++;

					if (accountScore.score < score) {
						accountScore.score = score;
					}
				}
			}
		}

		for (let accountId in accountScores) {
			let accountScore = accountScores[accountId];
			let numMatchesScore = accountScore.numMatches / totalMatches;
			let newScore = (accountScore.score * (1/2)) + (numMatchesScore * (1/2));

			matches.push({
				accountId: accountId,
				score: newScore
			});
		}

		matches.sort((a, b) => {
			return b.score - a.score;
		});

		matches = exactMatches.concat(matches);

		return matches;
	}

	static getDescriptionScore (d1, d2) {
		// TODO: id regex [ \t#]([\d-]{3,})
		let score = 0;

		if (d2 === d1) {
			score = 1;
		} else {
			let d1Length = d1.length,
				d2Length = d2.length,
				searchStarted = false,
				numMatchedChars = 0,
				j = 0;

			for (let i = 0; i < d2Length; i++) {
				let char = d2[i];

				if (searchStarted) {
					if (d1[++j] === char) {
						numMatchedChars++;
					} else {
						break;
					}
				} else {
					for (; j < d1Length; j++) {
						if (d1[j] === char) {
							searchStarted = true;
							numMatchedChars++;
							break;
						}
					}

					if (!searchStarted) {
						break;
					}
				}
			}

			score += numMatchedChars / d2Length;
		}

		return score;
	}

	static getAmountScore (a1, a2) {
		if (a2 === a1) {
			return 1;
		} else {
			let difference = Math.abs(a2 - a1);
			return Math.max(0, 1 - (difference / Math.abs(a2)));
		}
	}

}
