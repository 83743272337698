export const Routes = {};

Routes.HOME = "";
Routes.LOAD = "load";
Routes.ACCOUNTS = "accounts";
Routes.ACCOUNT = "account/:accountId";
Routes.ACCOUNT_TRANSACTIONS = Routes.ACCOUNT + "/transactions";
Routes.REPORTS = "reports";
Routes.BUDGETS = "budgets";
Routes.BUDGET = "budget/:budgetID";
