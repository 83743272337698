import * as _ from "lodash";
import {DateUtils} from "../../rovert/scripts/DateUtils";
import {AppModel} from "./AppModel";
import {TransactionModel, TransactionModels} from "./TransactionModel";

export class BudgetTransactionModel extends TransactionModel {

	defaults () {
		return _.extend({}, TransactionModel.prototype.defaults(), {
			"dynamicAmount": null, // [String] accountBalance, accountBalanceInverted
			"dynamicAmountAccountIds": null, // [String]
			"startDate": null, // [Number->Date]
			"endDate": null, // [Number->Date]
			"endNumOccurrences": null, // [Number]
			"repeats": null, // [Number]
			"repeatsEvery": null, // [Number]
			"repeatsBy": null, // [Number]
			"repeatsByValue": null, // [Number]

			"startDateText": null, // [String]
			"endDateText": null, // [String]
			"repeatsText": null, // [String]
			"repeatsEveryText": null, // [String]
			"repeatsByText": null, // [String]
			"repeatsByValueText": null, // [String]
			"isOld": false, // [Boolean]
			"isActive": false, // [Boolean]
		});
	}

	constructor (params) {
		if (params && typeof params.startDate === "string") {
			params.startDate = new Date(params.startDate);
		}
		if (params && typeof params.endDate === "string") {
			params.endDate = new Date(params.endDate);
		}

		super(params);

		this.updateRepeatsText();
		this.updateIsActive();

		this.on("change:startDate", this.updateDateText, this);
		this.on("change:endDate", this.updateDateText, this);
		this.on("change:repeats", this.updateRepeatsText, this);
		this.on("change:repeatsEvery", this.updateRepeatsText, this);
		this.on("change:repeatsBy", this.updateRepeatsText, this);
		this.on("change:repeatsByValue", this.updateRepeatsText, this);
	}

	getTransactions (startDate, endDate) {
		let transactions = [];

		let data = {
			startDate: this.get("startDate"),
			endDate: this.get("endDate"),
			endNumOccurrences: this.get("endNumOccurrences"),
			repeats: this.get("repeats"),
			every: this.get("repeatsEvery"),
			by: { type: this.get("repeatsBy"), value: this.get("repeatsByValue") },
		};

		if (!data.endDate || data.endDate > endDate) {
			data.endDate = endDate;
		}

		if (!data.repeats && startDate <= data.startDate && data.startDate <= endDate) {
			// This is a one-time transaction.

			let transaction = new TransactionModel(super.toJSON());
			transaction.set("date", data.startDate);

			transactions.push(transaction);

			return transactions;
		}

		let dates = [];

		while (true) {
			let nextOccurrence = DateUtils.getNextOccurrence(data, dates);

			if (nextOccurrence !== null) {
				let transaction = new TransactionModel(super.toJSON());
				transaction.set("dynamicAmount", this.get("dynamicAmount"));
				transaction.set("dynamicAmountAccountIds", this.get("dynamicAmountAccountIds"));
				transaction.set("date", nextOccurrence);

				transactions.push(transaction);

				dates.push(nextOccurrence);
			} else {
				break;
			}
		}

		for (let l = transactions.length, i = 0; i < l; i++) {
			let transaction = transactions[i];

			if (transaction.get("date") < startDate) {
				transactions.splice(i, 1);
				i--;
				l--;
				continue;
			} else {
				// Can assume any further transactions are after the startDate.
				break;
			}
		}

		return transactions;
	}

	updateDateText () {
		super.updateDateText();

		let startDate = this.get("startDate"),
			startDateText = startDate ? startDate.toString("MM/dd/yyyy") : "",
			endDate = this.get("endDate"),
			endDateText = endDate ? endDate.toString("MM/dd/yyyy") : "";

		this.set({
			startDateText: startDateText,
			endDateText: endDateText
		});
	}

	updateRepeatsText () {
		let repeats = this.get("repeats"),
			repeatsEvery = this.get("repeatsEvery"),
			repeatsBy = this.get("repeatsBy"),
			repeatsByHash = repeats === DateUtils.REPEATS.WEEKLY ? DateUtils.REPEATS_WEEKLY_BY : DateUtils.REPEATS_MONTHLY_BY,
			repeatsByValue = this.get("repeatsByValue");

		let repeatsEntry = repeats ? Object.entries(DateUtils.REPEATS).find(([key, value]) => value === repeats) : undefined,
			repeatsByEntry = repeatsBy ? Object.entries(repeatsByHash).find(([key, value]) => value === repeatsBy) : undefined;

		let repeatsText = repeatsEntry !== undefined ? repeatsEntry[0] : "",
			repeatsEveryText = repeatsEvery,
			repeatsByText = repeatsByEntry !== undefined ? repeatsByEntry[0] : "",
			repeatsByValueText = repeatsByValue;

		this.set({
			repeatsText: repeatsText,
			repeatsEveryText: repeatsEveryText,
			repeatsByText: repeatsByText,
			repeatsByValueText: repeatsByValueText,
		});
	}

	updateIsActive () {
		let startDate = DateUtils.normalizeDate(this.get("startDate")),
			endDate = DateUtils.normalizeDate(this.get("endDate")),
			today = DateUtils.normalizeDate(new Date()),
			isActive = startDate <= today && (endDate === null || today <= endDate),
			isOld = (endDate && endDate < today) || (endDate === null && this.get("repeats") === null && startDate < today);

		// TODO: account for endNumOccurrences.

		this.set("isActive", isActive);
		this.set("isOld", isOld);
	}

	removeDynamicAccount (id) {
		if (this.get("dynamicAmountAccountIds") === id) {
			this.set("dynamicAmountAccountIds", null);
		}
	}

	toJSON () {
		let json = super.toJSON();

		let attributes = this.attributes;

		json.id = attributes.id;
		json.dynamicAmount = attributes.dynamicAmount;
		json.dynamicAmountAccountIds = attributes.dynamicAmountAccountIds;
		json.startDate = attributes.startDate ? attributes.startDate.toString("MM-dd-yyyy") : null;
		json.endDate = attributes.endDate ? attributes.endDate.toString("MM-dd-yyyy") : null;
		json.endNumOccurrences = attributes.endNumOccurrences;
		json.repeats = attributes.repeats;
		json.repeatsEvery = attributes.repeatsEvery;
		json.repeatsBy = attributes.repeatsBy;
		json.repeatsByValue = attributes.repeatsByValue;

		// Remove unneeded data to save space:

		for (var key in json) {
			if (json[key] === null) {
				delete json[key];
			}
		}

		return json;
	}

}

export class BudgetTransactionModels extends TransactionModels {

	static getInstance () {
		return AppModel.getInstance().get("budgetTransactions");
	}

	get model () { return BudgetTransactionModel; }

	comparator (a, b) {
		let aStartDate = a.get("startDate"),
			aEndDate = a.get("endDate"),
			bStartDate = b.get("startDate"),
			bEndDate = b.get("endDate");

		let result = null;

		// TODO: decide if this is a better sort order.
		// if (aEndDate && bEndDate) {
		// 	result = aEndDate - bEndDate;
		// } else if (aEndDate === null && bEndDate === null) {
			result = aStartDate - bStartDate;
		// } else if (aEndDate) {
		// 	result = aEndDate - bStartDate;
		// } else {
		//	 result = aStartDate - bEndDate;
		// }

		return result;
	}

}
