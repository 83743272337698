import {CSVParser} from "../parser/CSVParser";
import {DialogModel} from "../model/DialogModel";
import {DialogActionModel} from "../model/DialogActionModel";
import {TransactionModel} from "../model/TransactionModel";
import {TransactionAccountModels} from "../model/TransactionAccountModel";
import {AbstractDialog} from "./AbstractDialog";
import {ImportTransactionListItem} from "../component/ImportTransactionListItem";
import {ChangeAccountView} from "../view/ChangeAccountView";

export class ImportDialog extends AbstractDialog {

	static get STEP () {
		return this._STEP || (this._STEP = {
			SELECT_FILE: ".importDialog_selectFileStep",
			PARSE_FILE: ".importDialog_parseFileStep",
			CONFIGURE_TRANSACTIONS: ".importDialog_configureTransactionsStep"
		});
	}

	static get STEPS () {
		return this._STEPS || (this._STEPS = [
			this.STEP.SELECT_FILE,
			this.STEP.PARSE_FILE,
			this.STEP.CONFIGURE_TRANSACTIONS
		]);
	}

	static get STEP_TITLE () {
		return this._STEP_TITLE || (this._STEP_TITLE = {
			[this.STEP.SELECT_FILE]: "Upload File",
			[this.STEP.PARSE_FILE]: "Format File",
			[this.STEP.CONFIGURE_TRANSACTIONS]: "Configure"
		});
	}

	get events () {
		return {
			"change .importDialog_selectFileStep_btn": "_handleSelectFileBtnChange",
			"change #importDialog_parseFileStep_firstRowIsHeader": "_handleParseFileFirstRowHeaderChange"
		};
	}

	constructor (params) {
		params = params || {};

		params.model = new DialogModel({
			_title: "Import Transactions",
			title: "Import Transactions",
			actions: [
				new DialogActionModel({ id: "prev", data: -1, label: "Prev" }),
				new DialogActionModel({ id: "next", data: 1, label: "Next" })
			]
		});

		super(params);

		this.transactionsFilter = params.transactionsFilter;

		this._currentStepActions = null;
		this._actions = this.getActions();
		this._chooseAccountActions = [
			new DialogActionModel({ id: "close", label: "Close" })
		];

		this.step = null;
		this.stepIndex = -1;
		this.stepEl = null;

		this.$fileUploadProgress = null;
		this.fileName = null;
		this.fileData = null;
		this.arrayData = null;
		this._firstRowIsHeader = false;

		this.$parseFileFirstRowIsHeader = null;
		this.$parseFileDateFormat = null;
		this.parseFileHeaders = [];
		this.$parseFileTransactions = null;

		this.$configureTransactions = null;
		this.changeAccountView = null;

		this.transactions = null;

		this.setTemplate("dialog.ImportDialog");

		this.setStep(ImportDialog.STEP.SELECT_FILE);
	}

	/**
	 * Override.
	 */
	_configUIBeforeReplace () {
		super._configUIBeforeReplace();

		this.$fileUploadProgress = this.$(".importDialog_selectFileStep_progress");

		this.$parseFileFirstRowIsHeader = this.$("#importDialog_parseFileStep_firstRowIsHeader");
		this.$parseFileDateFormat = this.$("#importDialog_parseFileStep_dateFormat");
		this.$parseFileTransactions = this.$(".importDialog_parseFileStep_transactions");

		this.$configureTransactions = this.$(".importDialog_configureTransactionsStep_transactions");
		this.changeAccountView = new ChangeAccountView({
			el: this.$(".importDialog_configureTransactionsStep_changeAccount")
		});
		this.listenTo(this.changeAccountView, ChangeAccountView.ACCOUNT_CHANGED, this._handleAccountChanged);
	}

	setStep (step) {
		if (this.step) {
			this.stepEl.removeClass("in");
		}

		this.step = step;
		this.stepIndex = ImportDialog.STEPS.indexOf(this.step);
		this.stepEl = this.$(this.step);

		if (this.step === ImportDialog.STEP.CONFIGURE_TRANSACTIONS) {
			this._updateConfigureTransactions();
		}

		this.stepEl.addClass("in");

		let subTitle = ImportDialog.STEP_TITLE[this.step];
		if (this.step === ImportDialog.STEP.PARSE_FILE) {
			subTitle = subTitle + " '" + this.fileName + "'";
		}
		this.model.set("title", this.model.get("_title") + " - " + subTitle);

		this._updateActionBtnStates();
	}

	_goToNewStep (direction) {
		let newStepIndex = this.stepIndex + direction;
		if (newStepIndex < ImportDialog.STEPS.length) {
			let newStep = ImportDialog.STEPS[newStepIndex];
			this.setStep(newStep);
		} else {
			this.trigger("complete", this.transactions);
			this.close();
		}
	}

	_updateActionBtnStates () {
		let hasPrevStep = ImportDialog.STEPS[this.stepIndex - 1],
			hasNextStep = ImportDialog.STEPS[this.stepIndex + 1];

		switch (this.step) {
			case ImportDialog.STEP.SELECT_FILE:
				if (!this.fileData) { hasNextStep = false; }
				break;
			case ImportDialog.STEP.PARSE_FILE:
				break;
			case ImportDialog.STEP.CONFIGURE_TRANSACTIONS:
				let noDstAccountsTransactions = this.transactions.where(transaction => {
					return transaction.get("dstAccountIds").length === 0;
				});
				hasNextStep = noDstAccountsTransactions.length === 0;
				break;
		}

		this.model.getAction("prev").set("disabled", !hasPrevStep);
		this.model.getAction("next").set("disabled", !hasNextStep);
	}

	/**
	 * Override.
	 */
	_handleActionBtnClick (btn) {
		switch (this._currentStepActions) {
			case null:
				this._goToNewStep(btn.model.get("data"));
				break;
			case ImportDialog.STEP.CONFIGURE_TRANSACTIONS:
				this._toggleChooseAccount(false);
				break;
		}
	}

//******************************************************************************
// SELECT FILE STEP

	_handleSelectFileBtnChange (event) {
		let files = event.target.files,
			file = files[0];

		if (file) {
			this.fileName = file.name;

			let reader = new FileReader();
			reader.addEventListener("progress", this._handleFileProgress.bind(this));
			// reader.addEventListener("abort", this._handleFileAbort.bind(this)); TODO.
			// reader.addEventListener("error", this._handleFileError.bind(this)); TODO.
			reader.addEventListener("load", this._handleFileLoad.bind(this));
			reader.readAsText(file);
		}
	}

	_handleFileProgress (event) {
		var progress = 0;

		if (event.lengthComputable) {
			progress = event.loaded / event.total;
		}

		progress = (progress * 100) | 0;
		progress = `${progress}%`;

		this.$fileUploadProgress.html(progress);
	}

	_handleFileLoad (event) {
		this.fileData = event.target.result;

		this._updateActionBtnStates();
		this._parseFile();

		setTimeout(() => {
			if (this.step === ImportDialog.STEP.SELECT_FILE) {
				this._goToNewStep(1);
			}
		}, 1000);
	}

//******************************************************************************
// PARSE FILE STEP

	_parseFile () {
		let arrayData = CSVParser.getArray(this.fileData),
			array = arrayData.array,
			firstRowIsHeader = arrayData.guesses.firstRowIsHeader,
			columnTypes = arrayData.guesses.columnTypes;

		this.arrayData = arrayData;

		this.$parseFileTransactions.empty();
		this.parseFileHeaders = [];

		// Add column type headers:

		let numColumns = array[0].length,
			COLUMN_HEADERS = TransactionModel.COLUMN_HEADERS,
			COLUMN_HEADER_NAMES = TransactionModel.COLUMN_HEADER_NAMES;

		let $row = $(document.createElement("div"));
		$row.addClass("row row-header");

		for (let i = 0; i < numColumns; i++) {
			let $column = $(document.createElement("div"));
			$column.addClass("column");

			let $select = $(document.createElement("select"));
			$select.addClass("columnTypeDropdown");
			$select.on("change", this._handleParseFileSelectChange.bind(this, $select));

			let $placeholder = $(document.createElement("option"));
			$placeholder.attr("selected", "selected");
			$placeholder.attr("value", "");
			$placeholder.text("- none -");
			$select.append($placeholder);

			let columnType = columnTypes[i],
				columnIndex = -1;

			for (let key in COLUMN_HEADER_NAMES) {
				columnIndex++;

				let $option = $(document.createElement("option"));
				$option.attr("value", key);
				$option.text(COLUMN_HEADER_NAMES[key]);

				$select.append($option);

				if (columnType && COLUMN_HEADERS[key] === columnType) {
					$select[0].selectedIndex = columnIndex + 1;
				}
			}

			$column.append($select);
			$row.append($column);

			this.parseFileHeaders.push($select);
		}

		this.$parseFileTransactions.append($row);

		// Add transactions:

		for (let row of array) {
			let $row = $(document.createElement("div"));
			$row.addClass("row");

			for (let column of row) {
				let $column = $(document.createElement("div"));
				$column.addClass("column");
				$column.html(column);

				$row.append($column);
			}

			this.$parseFileTransactions.append($row);
		}

		this._setFirstRowIsHeader(firstRowIsHeader);
	}

	_handleParseFileFirstRowHeaderChange (event) {
		this._setFirstRowIsHeader(this.$parseFileFirstRowIsHeader[0].checked);
	}

	_handleParseFileSelectChange ($targetSelect, event) {
		let value = event.target.value;

		for (let $select of this.parseFileHeaders) {
			if ($select === $targetSelect) { continue; }
			if ($select.val() === value) {
				$select.val("");
				break;
			}
		}
	}

	_setFirstRowIsHeader (isHeader) {
		this._firstRowIsHeader = isHeader;
		this.$parseFileFirstRowIsHeader[0].checked = isHeader;
		this.$parseFileTransactions.children().eq(1).attr("data-isHeader", isHeader.toString());
	}

//******************************************************************************
// CONFIGURE TRANSACTIONS STEP

	_updateConfigureTransactions () {
		let array = this.arrayData.array.slice();

		if (this._firstRowIsHeader) {
			array.shift();
		}

		var $headers = this.$configureTransactions.children().first();
		this.$configureTransactions.empty();
		this.$configureTransactions.append($headers);

		let selectedDateFormatOptionEl = this.$parseFileDateFormat[0].selectedOptions[0],
			dateLocations = [
				parseInt(selectedDateFormatOptionEl.getAttribute("data-month"), 10),
				parseInt(selectedDateFormatOptionEl.getAttribute("data-day"), 10),
				parseInt(selectedDateFormatOptionEl.getAttribute("data-year"), 10),
			];

		// Add transactions:

		let parseHeaders = [],
			COLUMN_HEADERS = TransactionModel.COLUMN_HEADERS;

		for (let $select of this.parseFileHeaders) {
			let headerKey = $select[0].value,
				header = headerKey === "" ? null : COLUMN_HEADERS[headerKey];

			parseHeaders.push(header);
		}

		let transactions = CSVParser.parseArray(array, parseHeaders, dateLocations, this.getParam("account").get("amountDisplayRatio")),
			srcAccountId = this.params.account.id;

		transactions.forEach((transaction) => {
			transaction.set("srcAccountId", srcAccountId);

			let accountMatches = TransactionAccountModels.getTransactionMatches(transaction, this.transactionsFilter),
				accountMatch = accountMatches[0],
				accountIds = accountMatch ? accountMatch.accountId : "105", // 105 = ORGANIZE account. Hardcoded for now.
				accountConfidence = accountMatch ? accountMatch.score : null;

			if (accountConfidence !== null) {
				transaction.set("accountConfidence", accountConfidence);
			}

			transaction.setDstAccountIds(accountIds);
			transaction.updateAccountLabel([srcAccountId]);
			transaction._accountMatches = accountMatches;

			let item = new ImportTransactionListItem({
				transaction: transaction,
				events: {
					"click .transactionListItem_account": this._handleConfigureTransactionAccountClick.bind(this, transaction)
				}
			});

			this.$configureTransactions.append(item.el);
		});

		this.transactions = transactions;
	}

	_handleConfigureTransactionAccountClick (transaction) {
		this.changeAccountView.setTransaction(transaction, this.transactionsFilter, transaction._accountMatches);

		this._toggleChooseAccount(true);
	}

	_toggleChooseAccount (isShowing) {
		this._toggleBodyScroll(!isShowing);

		this._currentStepActions = isShowing ? ImportDialog.STEP.CONFIGURE_TRANSACTIONS : null;

		var actions = isShowing ? this._chooseAccountActions : this._actions;
		this.setActions(actions);

		if (!isShowing) {
			this._updateActionBtnStates();
		}

		this.changeAccountView.$el.scrollTop(0);
		this.changeAccountView.$el.toggleClass("in", isShowing);
	}

	_handleAccountChanged () {
		this._toggleChooseAccount(false);
	}

}
