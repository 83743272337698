import Backbone from "backbone";
import {Routes} from "../data/Routes";

let instance = null;

export class AppRouter extends Backbone.Router {

	static getInstance () {
		if (!instance) {
			instance = new AppRouter();
		}

		return instance;
	}

	constructor (params) {
		params = params || {};

		let routes = {};

		for (let key in Routes) {
			let route = Routes[key];
			routes[route] = route;
		}

		params.routes = routes;

		super(params);
	}

	start () {
		Backbone.history.start({
			root: "",
			pushState: false
		});
	}

}
