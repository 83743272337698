export class ServerDelegate {

	static getInstance () {
		var instance = this.instance;

		if (!instance) {
			instance = new ServerDelegate();
			this.instance = instance;
		}

		return instance;
	}

	static sendRequest (...args) { this.getInstance().sendRequest(...args); }

	sendRequest (request) {
		var requestData = {
			url: request.url,
			method: request.method,
			data: request.getData(),
			timeout: request.timeout,
			success: this._handleRequestSuccess.bind(this, request),
			error: this._handleRequestError.bind(this, request)
		};

		if (request.crossDomain !== null) {
			requestData.crossDomain = request.crossDomain;
		}

		var jqXHR = $.ajax(requestData);
	}

	_handleRequestSuccess (request, response) {
		request.handleSuccess(response);
	}

	_handleRequestError (request, jqXHR) {
		request.handleError(jqXHR.responseText);
	}

}
