import {AbstractModel} from "../../rovert/scripts/AbstractModel";
import {DialogActionModel} from "./DialogActionModel";
import {DialogActionModels} from "./DialogActionModel";

export class DialogModel extends AbstractModel {

	defaults () {
		return {
			title: "",
			actions: null,

			modalLocked: false
		};
	}

	constructor (params) {
		super(params);

		this.setActions(params.actions);
	}

	setActions (actions) {
		let labelActions;

		if (actions instanceof Array) {
			if (typeof actions[0] === "string") {
				labelActions = actions;
				actions = null;
			} else {
				actions = new DialogActionModels(actions);
			}
		}

		if (!actions) {
			actions = new DialogActionModels();
		}

		this.set("actions", actions);

		if (labelActions) {
			this.addActionsByLabel(labelActions);
		}
	}

	addAction (action) {
		this.get("actions").add(action);
	}

	addActionByLabel (label) {
		var action = new DialogActionModel({
			label: label
		});

		this.addAction(action);
	}

	addActionsByLabel (labels) {
		for (let label of labels) {
			this.addActionByLabel(label);
		}
	}

	getAction (id) {
		return this.get("actions").get(id);
	}

	getActions () {
		return this.get("actions");
	}

}
