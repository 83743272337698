import {AbstractView} from "../../rovert/scripts/AbstractView";

export class HomeView extends AbstractView {

	constructor (params) {
		super(params);

		this.setTemplate("view.HomeView");
	}

}
