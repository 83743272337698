import {AbstractView} from "../../rovert/scripts/AbstractView";
import {AppRouter} from "../router/AppRouter";
import {AccountModels} from "../model/AccountModel";

export class AccountsView extends AbstractView {

	get events () {
		return {
			"click .accountsView_addAccountBtn": "_handleAddAccountBtnClick",
			"click .accountsView_removeAccountBtn": "_handleRemoveAccountBtnClick",
			"click .accountsView_renameAccountBtn": "_handleRenameAccountBtnClick",
		};
	}

	constructor (params) {
		super(params);

		this.$accounts = null;
		this.accountsTree = null;

		this.$addAccountLabel = null;
		this.$addAccountParentId = null;

		this.$removeAccountId = null;

		this.$renameAccountId = null;
		this.$renameAccountLabel = null;

		this.setTemplate("view.AccountsView");

		let accounts = AccountModels.getInstance();
		this.listenTo(accounts, "add", _.debounce(this.refreshList));
		this.listenTo(accounts, "remove", _.debounce(this.refreshList));
	}

	_configUIBeforeReplace () {
		this.$accounts = this.$(".accountsView_accountsTree");
		this.$accounts.on("dblclick", ".jstree-anchor", this._handleAccountTreeItemDoubleClick.bind(this));

		this.refreshList();

		this.$addAccountLabel = this.$(".accountsView_addAccountLabel");
		this.$addAccountParentId = this.$(".accountsView_addAccountParentId");

		this.$removeAccountId = this.$(".accountsView_removeAccountId");

		this.$renameAccountId = this.$(".accountsView_renameAccountId");
		this.$renameAccountLabel = this.$(".accountsView_renameAccountLabel");
	}

	refreshList () {
		let accounts = AccountModels.getInstance();
		let data = accounts.getJSTreeData();

		if (this.accountsTree) {
			this.accountsTree.destroy();
		}

		this.$accounts.jstree({
			core: {
				data: data
			}
		});

		this.accountsTree = this.$accounts.jstree();
	}

	_handleAccountTreeItemDoubleClick (event) {
		let nodeID = event.target.id.replace("_anchor", "");
		if (nodeID) {
			this.navigateToAccount(nodeID);
		}
	}

	_handleAddAccountBtnClick (event) {
		AccountModels.getInstance().createNewAccount(this.$addAccountLabel.val(), this.$addAccountParentId.val());
	}

	_handleRemoveAccountBtnClick (event) {
		let accountId = this.$removeAccountId.val();

		if (accountId !== "") {
			let account = AccountModels.getInstance().get(accountId);

			if (window.confirm("Are you sure you want to delete this account and all its descendant accounts?")) {
				AccountModels.getInstance().remove(account);

				this.$removeAccountId.val("");
			}
		}
	}

	_handleRenameAccountBtnClick (event) {
		let accountId = this.$renameAccountId.val();

		if (accountId !== "") {
			let account = AccountModels.getInstance().get(accountId);
			let name = this.$renameAccountLabel.val();

			account.set("name", name);

			this.refreshList();
		}
	}

	navigateToAccount (accountId) {
		AppRouter.getInstance().navigate("/account/" + accountId + "/transactions", { trigger: true });
	}

}
