export class Utils {

	static isSameArray (a1, a2) {
		if (a1.length !== a2.length) { return false; }

		let a2b = a2.slice();

		for (let l = a1.length, i = 0; i < l; i++) {
			let i2 = a2b.indexOf(a1[i]);
			if (i2 >= 0) {
				a2b.splice(i2, 1);
			}
		}

		return a2b.length === 0;
	}

}
