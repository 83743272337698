import {AbstractView} from "./AbstractView";

export class SimpleTemplateView extends AbstractView {

	constructor (params) {
		super(params);

		this.setTemplate(params.template);
	}

}
