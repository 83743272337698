export class UpgradeUtil  {

	/**
	 * Upgrades an outdated database.
	 */
	static upgradeDatabase (database) {
		if (!database) {
			database = {
				fileName: "MyBudget.json",
				saveTimestamp: null,
				version: 6,
				owners: [],
				banks: [],
				accounts: [
					{
						"id": "0",
						"name": "__ROOT__",
					},{
						"id": "1",
						"name": "Assets",
						"parentAccountId": "0",
					},{
						"id": "2",
						"name": "Liabilities",
						"parentAccountId": "0",
					},{
						"id": "3",
						"name": "Income",
						"parentAccountId": "0",
					},{
						"id": "4",
						"name": "Expenses",
						"parentAccountId": "0",
					},{
						"id": "5",
						"name": "Equity",
						"parentAccountId": "0",
					},{
						"id": "6",
						"name": "UNORGANIZED",
						"parentAccountId": "0",
					}
				],
				transactions: [],
				budgetTransactions: [],
				budgets: [],
				currentBudgetID: null,
			};
		}

		if (database.version === undefined || typeof database.version === "string") {
			database.version = 1;
		}

		if (database.version <= 1) {
			database.budgets = [];
			database.version = 2;
		}

		if (database.version === 2) {
			database.budgetTransactions = [];
			database.currentBudgetID = null;
			database.version = 3;
		}

		if (database.version === 3) {
			let amountDisplayRatio = 0.01;

			database.fileName = database.fileName || "MyBudget.json";
			database.accounts.forEach(account => {
				account.parentAccountId = account.parentAccountID;
				delete account.parentAccountID;

				account.balance = Math.round(account.balance / amountDisplayRatio);
			});
			database.transactions.forEach(transaction => {
				transaction.srcAccountId = transaction.accountID;
				transaction.dstAccountIds = transaction.accountIDs;

				delete transaction.accountID;
				delete transaction.accountIDs;

				transaction.date = (new Date(transaction.date)).toString("MM-dd-yyyy");

				if (transaction.hasOwnProperty("postDate")) {
					transaction.postDate = (new Date(transaction.postDate)).toString("MM-dd-yyyy");
				}

				transaction.amount = Math.round(transaction.amount / amountDisplayRatio);

				transaction.description = transaction.description.trim();
			});
			database.budgetTransactions.forEach(transaction => {
				transaction.srcAccountId = transaction.accountID;
				transaction.dstAccountIds = transaction.accountIDs;
				transaction.dynamicAmountAccountIds = transaction.dynamicAmountAccountIDs;

				delete transaction.accountID;
				delete transaction.accountIDs;
				delete transaction.dynamicAmountAccountIDs;

				transaction.amount = Math.round(transaction.amount / amountDisplayRatio);
			});
			database.version = 4;
		}

		if (database.version === 4) {
			database.transactions.forEach((transaction, i) => {
				if (transaction.hasOwnProperty("postDate")) {
					transaction.transactionDate = transaction.date;
					transaction.date = transaction.postDate;

					delete transaction.postDate;
				}
			});

			database.version = 5;
		}

		if (database.version === 5) {
			let id = 0;
			database.budgetTransactions.forEach(transaction => {
				let newId = ++id + "";
				database.budgets.forEach(budget => {
					budget._oldBudgetTransactions = budget._oldBudgetTransactions || budget.budgetTransactions.slice();
					let index = budget._oldBudgetTransactions.indexOf(transaction.id);
					if (index >= 0) {
						budget.budgetTransactions[index] = newId;
					}
				});
				transaction.id = newId;
			});
			database.budgets.forEach(budget => {
				delete budget._oldBudgetTransactions;
			});

			database.version = 6;
		}

		return database;
	}

}
