import {AbstractView} from "../../rovert/scripts/AbstractView";

export class AccountView extends AbstractView {

	constructor (params) {
		super(params);

		this.setTemplate("view.AccountView");
	}

}
