import {NumberUtils} from "../../rovert/scripts/NumberUtils";
import {AbstractView} from "../../rovert/scripts/AbstractView";
import {TransactionModels} from "../model/TransactionModel";
import {AccountModels} from "../model/AccountModel";

export class ReportsView extends AbstractView {

	constructor (params) {
		super(params);

		this.startDate = null;
		this.endDate = null;

		this.setTemplate("view.ReportsView");

		this.$table = this.$(".reportsView_table");
		this.$copyClosingBalancesBtn = this.$(".reportsView_copyClosingBalances");
		this.$includeDescendentBalances = this.$("#reportsView_includeDescendentBalances");

		let numMonthsInPast = 13;
		let endDate = new Date();
		// endDate = new Date(2018, 1, 31, 23, 59, 59, 999);
		let startDate = new Date(endDate.getFullYear(), endDate.getMonth() - (numMonthsInPast - 1));

		this.startDate = startDate;
		this.endDate = endDate;

		this._drawMonthlyReportTable(startDate, endDate);

		this._drawAnnualStats(startDate, endDate);

		this.$copyClosingBalancesBtn.click(this._handleCopyClosingBalancesBtnClick.bind(this));
		this.$includeDescendentBalances.change(this._drawMonthlyReportTable.bind(this, startDate, endDate));
	}

	_drawMonthlyReportTable (startDate, endDate) {
		this._monthlyReportTableRows = [];
		this.$table[0].innerHTML = "";

		let allTransactions = TransactionModels.getInstance();
		let accounts = AccountModels.getInstance();
		let accountsIDTree = accounts.getIDTree();

		let monthDatas = [];
		let numMonths = this._getNumMonths(startDate, endDate);
		for (let l = numMonths, i = 0; i < l; i++) {
			let monthDate = new Date(startDate);
			monthDate.setMonth(monthDate.getMonth() + i);
			monthDatas[i] = (monthDate.getMonth() + 1) + "/" + monthDate.getFullYear();
		}
		this._addRow(["Account"].concat(monthDatas), "th");

		let table = {};
		this._addAccountsFromIDTree(accountsIDTree, accounts, table, allTransactions, startDate, endDate);

		if (this.$includeDescendentBalances[0].checked) {
			for (let l = this._monthlyReportTableRows.length, i = l - 1; i >= 0; i--) {
				let row = this._monthlyReportTableRows[i];
				if (row.parentColumns !== null) {
					for (let jl = row.parentColumns.length, j = 1; j < jl; j++) {
						row.parentColumns[j] += row.columns[j];
					}
				}
			}
		}

		this._monthlyReportTableRows.forEach(row => {
			for (let l = row.columns.length, i = 1; i < l; i++) {
				row.columns[i] = row.columns[i].toLocaleString(undefined, { minimumFractionDigits: 2 });
			}

			this._addRow(row.columns);
		});

		// console.table(table);
		// console.log(table);
	}

	_addAccountsFromIDTree (idTree, accounts, table, allTransactions, startDate, endDate, parentColumns = null) {
		for (let l = idTree.length, i = 0; i < l; i++) {
			let data = idTree[i],
				account = accounts.get(data.id);

			let columns = this._addAccount(account, table, allTransactions, startDate, endDate, parentColumns);

			this._addAccountsFromIDTree(data.children, accounts, table, allTransactions, startDate, endDate, columns);
		}
	}

	_addAccount (account, table, allTransactions, startDate, endDate, parentColumns = null) {
		let monthDatas = [];

		let numMonths = this._getNumMonths(startDate, endDate);
		for (let l = numMonths, i = 0; i < l; i++) {
			monthDatas[i] = 0;
		}

		let accountData = {};
		let accountId = account.id;
		let accountLabel = ((new Array(account.getAncestralNames().length)).join("-")) + accountId + " " + account.get("name");
		// console.log("===", accountLabel, "===");

		let isDefaultMonth = true;
		let monthDate = new Date(startDate.getFullYear(), startDate.getMonth());
		let monthKey;

		for (let i = 0, l = allTransactions.length; i < l; i++) {
			let transaction = allTransactions.at(i);
			let date = transaction.get("date");

			if (date < startDate) { continue; }
			if (date > endDate) { break; }

			if (!transaction.hasAccount(accountId)) { continue; }

			monthKey = date.getFullYear() + "-" + (date.getMonth() + 1);

			if (accountData[monthKey] === undefined) {
				accountData[monthKey] = 0;
			}

			let amount = transaction.getAccountAmount(accountId) * transaction.get("amountDisplayRatio");

			accountData[monthKey] += amount;

			let monthI = this._getNumMonths(startDate, date) - 1;
			monthDatas[monthI] += amount;
		}

		for (let monthKey in accountData) {
			accountData[monthKey] = accountData[monthKey].toLocaleString(undefined, { minimumFractionDigits: 2 });
		}

		table[accountLabel] = accountData;

		let columns = [accountLabel].concat(monthDatas);

		this._monthlyReportTableRows.push({
			parentColumns: parentColumns,
			columns: columns
		});

		return columns;
	}

	_getNumMonths (startDate, endDate) {
		let numMonths = 0;

		let tempStartDate = new Date(startDate);
		while (tempStartDate.getFullYear() < endDate.getFullYear() ||
			  (tempStartDate.getFullYear() === endDate.getFullYear() && tempStartDate.getMonth() <= endDate.getMonth())) {
			numMonths++
			tempStartDate.setMonth(tempStartDate.getMonth() + 1);
		}

		return numMonths;
	}

	_addRow (columns, dataCellTag = "td") {
		let rowEl = document.createElement("tr");

		for (let l = columns.length, i = 0; i < l; i++) {
			let columnEl = document.createElement(dataCellTag);
			columnEl.innerHTML = columns[i];
			rowEl.appendChild(columnEl);
		}

		this.$table.append(rowEl);
	}

	_drawAnnualStats (startDate, endDate) {
		let allTransactions = TransactionModels.getInstance();

		let annualIncome = 0,
			annualExpenses = 0;

		let date,
			amount;

		// TODO: optimize forEach loops:
		allTransactions.forEach(t => {
			date = t.get("date");
			if (date >= startDate && date <= endDate) {
				let dstAccountIds = t.get("dstAccountIds");

				dstAccountIds.forEach(account => {
					amount = account.amount;
					if (amount >= 0) {
						annualIncome += amount;
					} else {
						annualExpenses += amount;
					}
				});
			}
		});

		annualExpenses *= -1;

		console.log(`Annual Income ($${annualIncome.toFixed(2)}) & Expenses ($${annualExpenses.toFixed(2)})`);
	}

	_configUIBeforeReplace () {
	}

	_handleCopyClosingBalancesBtnClick (event) {
		let allTransactions = TransactionModels.getInstance();
		let accounts = AccountModels.getInstance();

		let accountIds = [121, 122, 117, 119, 118, 120];

		let data = [
			// ["Date", "121 - Tangerine", "117 - Joint", "119 - K - Visa", "118 - T - Visa", "120 - T - CO"],
		];

		let date = new Date(this.startDate);
		while (date <= this.endDate) {
			data.push([new Date(date)]);
			date.setDate(date.getDate() + 1);
		}

		for (let l = accountIds.length, i = 0; i < l; i++) {
			let id = accountIds[i];
			let balances = this._getBalancesForAccount(accounts.get(id), this.startDate, this.endDate);

			for (let jl = balances.length, j = 0; j < jl; j++) {
				data[j][i + 1] = balances[j];
			}
		}

		for (let l = data.length, i = 0; i < l; i++) {
			let item = data[i];
			item[0] = item[0].toString("MMM d, yyyy");
			data[i] = item.join("\t");
		}

		let text = data.join("\n");

		navigator.clipboard.writeText(text).then(() => {
			console.log("Copied to clipboard.");
		}, () => {
			console.log("Something went wrong when copying to the clipboard.");
		});
	}

	_getBalancesForAccount (account, startDate, endDate) {
		startDate = new Date(startDate);
		endDate = new Date(endDate);

		let balances = [];

		let transactionsFilter = account.getTransactionsFilter();
		let transactions = transactionsFilter.get("transactions");
		let l = transactions.length, i = 0;

		let lastBalance = 0;

		while (startDate <= endDate) {
			for (; i < l; i++) {
				let transaction = transactions.at(i),
					transactionDate = transaction.get("date");

				if (transactionDate <= startDate) {
					lastBalance = NumberUtils.toFixed(transaction.get("balance") * transaction.get("amountDisplayRatio"), 2);
				} else if (transactionDate > startDate) {
					break;
				}
			}

			balances.push(lastBalance);

			startDate.setDate(startDate.getDate() + 1);
		}

		return balances;
	}

}
